import React, {
  createContext, useContext, useState, ReactNode,
} from 'react';
import api from '../services/api';

interface IContextProps {
	data: any;
	loadData: (value: string) => void;
	loading: boolean;
}

const GaleriaEventosContext = createContext({} as IContextProps);

interface IProps {
	children: ReactNode;
}

export function GaleriaEventosProvider({ children }: IProps) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = async (event_id: string) => {
    setLoading(true);

    const res = await api.get(`/web/event_gallery/${event_id}`);

    setData(res.data.items);
    setLoading(false);
  };

  return (
    <GaleriaEventosContext.Provider value={{ data, loadData, loading }}>
      {children}
    </GaleriaEventosContext.Provider>
  );
}

export function useGaleriaEventos() {
  return useContext(GaleriaEventosContext);
}
