import React from 'react';
import { MdPlayCircleFilled } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './styles.css';

interface Props {
	title: string;
	image: string;
	url: string;
	video?: boolean;
}

const Card: React.FC<Props> = ({
  title,
  image,
  url,
  video = false,
  children,
}) => (
  <div className="boxItem">
    <Link to={url} title={title}>
      <div
        style={{
					  backgroundImage: `url(${image})`,
					  backgroundSize: 'cover',
        }}
        title={title}
      />

      {video && (
      <div className="iconPlay">
        <MdPlayCircleFilled />
      </div>
      )}

      <div className="title">
        {title.length > 60 ? `${title.substr(0, 55)}...` : title}
      </div>

      {children}
    </Link>
  </div>
);

export default Card;
