import { Button } from '@material-ui/core';
import React, { FormEvent, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import InputField, { getField } from '../../components/InputField';
import { useCadastro } from '../../contexts/cadastro';
import './styles.css';

function Login() {
  const [form, setForm] = useState([
    {
      name: 'email', label: 'E-mail', value: '', isRequired: true, isError: false,
    },
    {
      name: 'password', label: 'Senha', value: '', isRequired: true, isError: false,
    },
  ]);

  const { loginCadastro } = useCadastro();
  const history = useHistory();
  const match = useRouteMatch();

  const onChange = ({ name, value }: any) => {
    setForm([
      ...form.map((f: any) => {
        if (f.name === name) {
          f.value = value;
        }

        return f;
      }),
    ]);
  };

  const handleSalvar = async (e: FormEvent) => {
    e.preventDefault();

    const data = {
      email: getField(form, 'email').value,
      password: getField(form, 'password').value,
    };

    const retLogin = await loginCadastro(data);

    if (retLogin) {
      const goBack = sessionStorage.getItem('goBack');

      if (goBack) {
        sessionStorage.removeItem('goBack');
        history.push(goBack);
      } else {
        history.push('/perfil');
      }
    }
  };

  const novoCadastro = (e: any) => {
    e.preventDefault();

    if (match.path.indexOf('/carrinho') > -1) {
      history.push('/carrinho/cadastro');
    } else {
      history.push('/cadastro');
    }
  };

  document.title = 'Login | Mulher com Palavra';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Tem alguma dúvida ou recado? Entre em contato conosco.';

  return (
    <div id="contentLogin">
      <form onSubmit={(e) => handleSalvar(e)} noValidate autoComplete="off">
        <div>
          <InputField field={getField(form, 'email')} onChange={onChange} />
        </div>
        <div>
          <InputField field={getField(form, 'password')} type="password" onChange={onChange} />
        </div>
        <div>
          <Button variant="contained" color="secondary" type="submit">
            Validar
          </Button>
        </div>
      </form>

      <div>
        <Button variant="outlined" onClick={(e) => novoCadastro(e)}>
          Novo cadastro
        </Button>
      </div>
    </div>
  );
}

export default Login;
