import axios from 'axios';
import swal from 'sweetalert';

const api = axios.create({
  // baseURL: process.env.REACT_APP_BACKEND_PORT,
  baseURL: 'https://mcp-backend.vercel.app/api',
});

api.interceptors.request.use(
  (config) => {
    config.headers['x-access-token'] = sessionStorage.getItem('mcp-customer-token');
    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status !== 304 && error.response.status !== 400) {
        if ((error.response && error.response.status === 401) || error.toString().indexOf('Error') > -1) {
          sessionStorage.removeItem('mcp-customer');
          sessionStorage.removeItem('mcp-customer-token');

          if (window.location.pathname !== '/') {
            window.location.href = '/login';
          }
        }

        if (!error.response.data.auth) {
          return error.response;
        }
      } else {
        swal(error.response.data.error, '', 'error');
      }
    } else {
      // sessionStorage.clear();
      swal('Nosso serviço está fora do ar neste momento', '', 'error');
    }

    return Promise.reject(error);
  },
);

export default api;
