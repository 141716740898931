import React, { useEffect } from 'react';
import { useLoja } from '../../contexts/loja';

import './styles.css';
import Card from '../../components/Card';
import { formatarURL } from '../../services/util';
import MoneyFormat from '../../services/money';

const Loja: React.FC = () => {
  const { data, loadData, loading } = useLoja();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  if (data.length === 0) {
    return (
      <div className="nenhumRegistro">
        <p>Desculpe, no momento não temos produtos disponível.</p>
        <p>Confira novamente em breve. :)</p>
        <br />
        <p>Equipe Mulher com Palavra</p>
      </div>
    );
  }

  document.title = 'Loja | Mulher com Palavra';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Confira nossos produtos e a agenda do Mulher com Palavra pelo mundo.';

  return (
    <div id="contentEvent">
      <div className="eventoItem">
        {data.map((item: any) => (
          <div
            key={item._id}
            style={{
							  textAlign: 'center',
							  display: 'flex',
							  flexDirection: 'column',
            }}
          >
            <Card title={item.title} url={`/detalhes/loja/${item._id}/${formatarURL(item.title)}`} image={item.url}>
              <span className="eventoDetalhes">
                <div
                  style={{
										  display: 'flex',
										  flexDirection: 'row',
										  color: '#333',
										  alignItems: 'center',
										  justifyContent: 'center',
                  }}
                >
                  {!item.value || item.value === 0 ? <div className="valorEvento">Gratuito</div> : <span className="valorEvento">{MoneyFormat(item.value)}</span>}
                </div>

                {item.value > 0 && !item.is_virtual && <div className="maisFrete">+ frete à calcular</div>}
              </span>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Loja;
