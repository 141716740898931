import React, { ReactNode } from 'react';
import { MdPlayCircleFilled } from 'react-icons/md';
import Carousel from 'react-material-ui-carousel';
import { Link } from 'react-router-dom';
import { formatarURL } from '../../services/util';

import './styles.css';

interface Props {
	data: object[];
	url: string;
	withLink?: boolean;
	withContent?: boolean;
	limitContent?: number;
	children?: ReactNode;
}

const CarouselCard: React.FC<Props> = ({
  data,
  url,
  withLink = true,
  withContent = false,
  limitContent = 50,
  children,
}) => (
  <Carousel
    navButtonsAlwaysVisible={data.length > 1}
    indicators={false}
    interval={5000}
		>
    {data.map((item: any) => (
      <span key={item._id}>
        <Link
          to={
								withLink
								  ? `${url}${item._id}/${formatarURL(item.title)}`
								  : `${url}`
							}
          title={item.title}
        >
          {item.video && (
          <div className="iconPlayMinistracoes">
            <MdPlayCircleFilled />
          </div>
          )}

          <img src={item.url} alt={item.title} title={item.title} />
          <div className="title">
            {item.title.length > 60
								  ? `${item.title.substr(0, 55)}...`
								  : item.title}
          </div>

          {withContent && item.content && (
          <div
            className="content"
            dangerouslySetInnerHTML={{
									  __html:
											item.content.length > limitContent
											  ? `${item.content.substr(0, limitContent - 5)}...`
											  : item.content,
            }}
          />
          )}
        </Link>
        {children}
      </span>
    ))}
  </Carousel>
);

export default CarouselCard;
