import React, { useEffect } from 'react';
import './global.css';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import { CookiesProvider } from 'react-cookie';
import Routes from './routes';

import { CadastroProvider } from './contexts/cadastro';
import { CarrinhoProvider } from './contexts/carrinho';
import { PedidoProvider } from './contexts/pedido';
import { PrincipalProvider } from './contexts/principal';
import { LojaProvider } from './contexts/loja';
import { HeaderProvider } from './contexts/header';
import { SobreProvider } from './contexts/sobre';
import { EquipeProvider } from './contexts/equipe';
import { ContatoProvider } from './contexts/contato';
import { EventosProvider } from './contexts/eventos';
import { GaleriaEventosProvider } from './contexts/galeria_eventos';
import { PalavrasProvider } from './contexts/palavras';
import { CategoriasProvider } from './contexts/categorias';
import { TestemunhosProvider } from './contexts/testemunhos';

import { getEstados } from './util';

function App() {
  Modal.setAppElement('#root');

  useEffect(() => {
    getEstados();
  }, []);

  return (
    <CookiesProvider>
      <CadastroProvider>
        <CarrinhoProvider>
          <PedidoProvider>
            <LojaProvider>
              <HeaderProvider>
                <PrincipalProvider>
                  <EquipeProvider>
                    <SobreProvider>
                      <EventosProvider>
                        <GaleriaEventosProvider>
                          <CategoriasProvider>
                            <ContatoProvider>
                              <PalavrasProvider>
                                <TestemunhosProvider>
                                  <Routes />
                                  <ToastContainer />
                                </TestemunhosProvider>
                              </PalavrasProvider>
                            </ContatoProvider>
                          </CategoriasProvider>
                        </GaleriaEventosProvider>
                      </EventosProvider>
                    </SobreProvider>
                  </EquipeProvider>
                </PrincipalProvider>
              </HeaderProvider>
            </LojaProvider>
          </PedidoProvider>
        </CarrinhoProvider>
      </CadastroProvider>
    </CookiesProvider>
  );
}

export default App;
