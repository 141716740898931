import React, {
  createContext, ReactNode, useContext, useState,
} from 'react';
import { useCookies } from 'react-cookie';
import swal from 'sweetalert';
import * as uuid from 'uuid';
import api from '../services/api';
import { isEmpty } from '../services/util';

export interface IAddress {
	address_postal_code: string;
	address_street: string;
	address_number: string;
	address_complement: string;
	address_district: string;
	address_city: string;
	address_state: string;
	address_email?: string;
}
interface IContextProps {
	updateCarrinho: (value: object[]) => void;
	saveCarrinho: (value: object) => void;
	saveAddress: (value: IAddress) => void;
	getCarrinho: () => any;
	delCarrinho: () => any;
	qtd: number;
	endereco: IAddress;
	loading: boolean;
	getSessionID: () => void;
	sessionId: string | null;
}

const CarrinhoContext = createContext({} as IContextProps);

interface IProps {
	children: ReactNode;
}

export function CarrinhoProvider({ children }: IProps) {
  const [qtd, setQtd] = useState(0);
  const [endereco, setEndereco] = useState<IAddress>({} as IAddress);
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null as any);
  const [cookies, setCookie, removeCookie] = useCookies(['MCP_PAGSEGURO']);

  const getSessionID = async () => {
    if (isEmpty(cookies.MCP_PAGSEGURO)) {
      try {
        const {
          data: { session_id },
        } = await api.get('/web/pagseguro/session');

        setSessionId(session_id);

        if (session_id) {
          const dtCookie = new Date();
          dtCookie.setMinutes(dtCookie.getMinutes() + 15);

          setCookie('MCP_PAGSEGURO', session_id, { expires: dtCookie });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setSessionId(cookies.MCP_PAGSEGURO);
    }
  };

  const updateCarrinho = (data: object[]) => {
    const carrinho = getCarrinho();
    carrinho.items = data;

    setQtd(carrinho.items.length);
    sessionStorage.setItem('mcp-carrinho', JSON.stringify(carrinho));
  };

  const delCarrinho = () => {
    sessionStorage.removeItem('mcp-carrinho');
    removeCookie('MCP_PAGSEGURO');
    setQtd(0);
    setSessionId(null);

    getCarrinho();
  };

  const saveCarrinho = (data: object) => {
    swal('Item adicionado ao carrinho', '', 'success');

    const carrinho = getCarrinho();

    carrinho.items.push({
      id: uuid.v4(),
      qtd: 1,
      item: { ...data },
    });

    setQtd(carrinho.items.length);
    sessionStorage.setItem('mcp-carrinho', JSON.stringify(carrinho));
  };

  const saveAddress = (data: IAddress) => {
    const carrinho = getCarrinho();

    setEndereco(data);
    carrinho.endereco = data;

    sessionStorage.setItem('mcp-carrinho', JSON.stringify(carrinho));
  };

  const getCarrinho = () => {
    setLoading(true);
    let carrinho: any = sessionStorage.getItem('mcp-carrinho');

    if (carrinho) {
      try {
        carrinho = JSON.parse(carrinho);
      } catch (e) {}
    } else if (!carrinho) {
      carrinho = {
        id: uuid.v4(),
        items: [],
      };

      sessionStorage.setItem('mcp-carrinho', JSON.stringify(carrinho));
    }

    setQtd(carrinho.items.length);
    setLoading(false);
    return carrinho;
  };

  return (
    <CarrinhoContext.Provider
      value={{
			  updateCarrinho,
			  getSessionID,
			  sessionId,
			  saveCarrinho,
			  saveAddress,
			  endereco,
			  getCarrinho,
			  delCarrinho,
			  loading,
			  qtd,
      }}
    >
      {children}
    </CarrinhoContext.Provider>
  );
}

export function useCarrinho() {
  return useContext(CarrinhoContext);
}
