import React, {
  createContext, useContext, useState, ReactNode,
} from 'react';
import swal from 'sweetalert';
import api from '../services/api';

interface IContextProps {
	dataBanners: any;
	dataByFilter: any;
	dataEventos: any;
	dataTestemunhos: any;
	loadDataBanners: () => void;
	loadDataByFilter: () => void;
	saveOracao: (value: object) => void;
	loadDataEventos: () => void;
	loadDataTestemunhos: () => void;
	saveNewsletter: (value: object) => Promise<unknown>;
	loading: boolean;
}

const PrincipalContext = createContext({} as IContextProps);

interface IProps {
	children: ReactNode;
}

export function PrincipalProvider({ children }: IProps) {
  const [dataBanners, setDataBanners] = useState([]);
  const [dataEventos, setDataEventos] = useState([]);
  const [dataTestemunhos, setDataTestemunhos] = useState([]);
  const [dataByFilter, setDataByFilter] = useState({
    video: [],
    notvideo: [],
    all: [],
  });
  const [loading, setLoading] = useState(false);

  const loadDataBanners = async () => {
    setLoading(true);

    const res = await api.get('/web/banner?qtd=999');

    setDataBanners(res.data.items);
    setLoading(false);
  };

  const loadDataEventos = async () => {
    setLoading(true);

    const res = await api.get('/web/event?qtd=3');

    setDataEventos(res.data.items);
    setLoading(false);
  };

  const loadDataTestemunhos = async () => {
    setLoading(true);

    const res = await api.get('/web/testimony?qtd=3');

    const testemunhos = res.data.items.map((i: any) => ({
      ...i,
      title: i.name,
      content: i.message,
    }));

    setDataTestemunhos(testemunhos);
    setLoading(false);
  };

  const saveNewsletter = (data: object) => new Promise(async (resolve, reject) => {
    try {
      const res = await api.post('/web/newsletter', data);

      // toast("Cadastro realizado com sucesso", {
      // 	position: toast.POSITION.BOTTOM_RIGHT,
      // 	type: toast.TYPE.SUCCESS,
      // 	autoClose: 2000,
      // });

      swal('Cadastro realizado com sucesso', '', 'success');

      resolve(res);
    } catch {
      // toast("Cadastro já realizado anteriormente", {
      // 	position: toast.POSITION.BOTTOM_RIGHT,
      // 	type: toast.TYPE.INFO,
      // 	autoClose: 2000,
      // });

      swal('Cadastro já realizado anteriormente', '', 'warning');

      reject();
    }
  });

  const saveOracao = async (data: object) => {
    await api.post('/web/prayer', data);
    swal('Oração enviada com sucesso', '', 'success');
  };

  const loadDataByFilter = async () => {
    setLoading(true);

    const resVideo = await api.post('/web/ministry/withfilter?qtd=4', {
      filter: 'video',
    });

    const resNotVideo = await api.post('/web/ministry/withfilter?qtd=3', {
      filter: 'notvideo',
    });

    setDataByFilter({
      video: [...resVideo.data.items] as any,
      notvideo: [...resNotVideo.data.items] as any,
      all: [...resVideo.data.items, ...resNotVideo.data.items] as any,
    });

    setLoading(false);
  };

  return (
    <PrincipalContext.Provider
      value={{
			  loading,
			  dataByFilter,
			  dataBanners,
			  dataEventos,
			  dataTestemunhos,
			  loadDataBanners,
			  loadDataByFilter,
			  saveOracao,
			  loadDataEventos,
			  loadDataTestemunhos,
			  saveNewsletter,
      }}
    >
      {children}
    </PrincipalContext.Provider>
  );
}

export function usePrincipal() {
  return useContext(PrincipalContext);
}
