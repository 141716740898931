import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';

export const getField = (form: any, name: any) => form.filter((f: any) => f.name === name)[0];

function TextMaskCustom(props: any) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      ref={(ref) => {
			  inputRef(ref ? ref.inputElement : null);
      }}
      {...other}
      mask={props.mask}
      showMask={false}
    />
  );
}

function InputField({
  field, regex = null, type = 'text', maxLength = null, onChange, onBlur, multiline = false,
}: any) {
  const [value, setValue] = useState(field.value || '');

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  useEffect(() => {
    onChange({ name: field.name, value });
  }, [value]);

  if (field.isError) {
    // Exibe um simples componente com Erro com Regex
    if (regex) {
      return (
        <TextField
          label={field.label}
          value={value}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => (onBlur ? onBlur(e) : null)}
          variant="outlined"
          name={field.name}
          required={field.isRequired}
          type={type}
          style={{ display: field.isVisible === false ? 'none' : 'block' }}
          InputLabelProps={{
					  shrink: true,
          }}
          InputProps={{
					  inputComponent: TextMaskCustom,
					  inputProps: { mask: field.mask, maxLength: maxLength || null },
          }}
          error
          fullWidth
          helperText={field.errorMessage ? field.errorMessage : 'Campo invalido'}
        />
      );
    }

    // Exibe um simples componente com Erro com Multiline
    if (multiline) {
      return (
        <TextField
          label={field.label}
          value={value}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => (onBlur ? onBlur(e) : null)}
          variant="outlined"
          name={field.name}
          required={field.isRequired}
          type={type}
          style={{ display: field.isVisible === false ? 'none' : 'block' }}
          InputProps={{
					  inputProps: { maxLength: maxLength || null },
          }}
          InputLabelProps={{
					  shrink: true,
          }}
          error
          fullWidth
          helperText={field.errorMessage ? field.errorMessage : 'Campo invalido'}
          multiline
          rows={5}
        />
      );
    }

    // Exibe um simples componente com Erro
    return (
      <TextField
        label={field.label}
        value={value}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => (onBlur ? onBlur(e) : null)}
        variant="outlined"
        name={field.name}
        required={field.isRequired}
        type={type}
        style={{ display: field.isVisible === false ? 'none' : 'block' }}
        InputProps={{
				  inputProps: { maxLength: maxLength || null },
        }}
        InputLabelProps={{
				  shrink: true,
        }}
        error
        fullWidth
        helperText={field.errorMessage ? field.errorMessage : 'Campo invalido'}
      />
    );
  }

  // Exibe um simples componente com Regex
  if (regex) {
    return (
      <TextField
        label={field.label}
        value={value}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => (onBlur ? onBlur(e) : null)}
        variant="outlined"
        name={field.name}
        required={field.isRequired}
        type={type}
        style={{ display: field.isVisible === false ? 'none' : 'block' }}
        InputLabelProps={{
				  shrink: true,
        }}
        InputProps={{
				  inputComponent: TextMaskCustom,
				  inputProps: { mask: field.mask, maxLength: maxLength || null },
        }}
      />
    );
  }

  // Exibe um simples componente com Multiline
  if (multiline) {
    return (
      <TextField
        label={field.label}
        value={value}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => (onBlur ? onBlur(e) : null)}
        variant="outlined"
        name={field.name}
        required={field.isRequired}
        type={type}
        style={{ display: field.isVisible === false ? 'none' : 'block' }}
        InputProps={{
				  inputProps: { maxLength: maxLength || null },
        }}
        InputLabelProps={{
				  shrink: true,
        }}
        multiline
        rows={5}
      />
    );
  }

  // Exibe um simples componente
  return (
    <TextField
      label={field.label}
      value={value}
      onChange={(e) => handleChange(e)}
      onBlur={(e) => (onBlur ? onBlur(e) : null)}
      variant="outlined"
      name={field.name}
      required={field.isRequired}
      type={type}
      style={{ display: field.isVisible === false ? 'none' : 'block' }}
      fullWidth
      InputProps={{
			  inputProps: { maxLength: maxLength || null },
      }}
      InputLabelProps={{
			  shrink: true,
      }}
    />
  );
}

export default InputField;
