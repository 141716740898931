import React, { useEffect, useState } from 'react';
import './styles.css';

import { Link, useParams } from 'react-router-dom';
import ReactModal from 'react-modal';
import { useGaleriaEventos } from '../../contexts/galeria_eventos';
import { useEventos } from '../../contexts/eventos';
import { formatarDataHora } from '../../services/util';
import ShareSocial from '../../components/ShareSocial';
import MoneyFormat from '../../services/money';

const GaleriaEventos: React.FC = () => {
  const { dataByID, loadDataByID } = useEventos();
  const { data, loadData, loading } = useGaleriaEventos();

  const params = useParams<{ event_id: string }>();
  const [showModal, setShowModal] = useState(false);
  const [currentImg, setCurrentImg] = useState({ url: '', title: '' });

  useEffect(() => {
    loadDataByID(params.event_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.event_id]);

  useEffect(() => {
    if (dataByID._id) {
      loadData(params.event_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataByID]);

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  return (
    <div id="contentGalleryEvents">
      <div className="sectionEvents">
        <img src={dataByID.url} alt={dataByID.title} title={dataByID.title} className="imagemGrande" />

        <span className="eventoDetalhes" style={{ width: 500 }}>
          {dataByID.start_date && !dataByID.end_date && <div className="dataEvento">{formatarDataHora(dataByID.start_date)}</div>}

          {dataByID.start_date && dataByID.end_date && (
          <div className="dataEvento">
            {formatarDataHora(dataByID.start_date)}
            {' '}
            {'>'}
            {' '}
            {formatarDataHora(dataByID.end_date)}
          </div>
          )}

          <div
            style={{
						  display: 'flex',
						  flexDirection: 'row',
						  color: '#333',
						  alignItems: 'center',
						  justifyContent: 'space-between',
            }}
          >
            <div className="tipoEvento">
              Evento
              {dataByID.type}
            </div>

            {!dataByID.value || dataByID.value === 0 ? <div className="valorEvento">Gratuito</div> : <span className="valorEvento">{MoneyFormat(dataByID.value)}</span>}
          </div>
        </span>

        <div className="title">{dataByID.title}</div>

        <div className="description" dangerouslySetInnerHTML={{ __html: dataByID.description }} />

        {dataByID.link && (
        <Link to={{ pathname: dataByID.link }} target="_blank" title="Clique aqui para se inscrever" className="btnVejaMais" style={{ margin: '0 10px' }}>
          Inscrever
        </Link>
        )}
      </div>

      <ShareSocial shareUrl={window.location.href} title={dataByID.title} />

      {data.length > 0 && <div className="pularLinha" />}

      {data.map((item: any) => (
        <div className="linha" key={item._id}>
          <img
            className="img"
            src={item.url}
            alt={item.title}
            title={item.title}
            onClick={() => {
							  setCurrentImg(item);
							  setShowModal(true);
            }}
          />
        </div>
      ))}

      <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <img className="imgModal" src={currentImg.url} alt={currentImg.title} title={currentImg.title} onClick={() => setShowModal(true)} />

        <br />
        <button onClick={() => setShowModal(false)}>Fechar</button>
      </ReactModal>
    </div>
  );
};

export default GaleriaEventos;
