import { Button } from '@material-ui/core';
import React, { FormEvent, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import ComboboxField from '../../components/ComboboxField';
import InputField, { getField } from '../../components/InputField';
import { useCadastro } from '../../contexts/cadastro';
import { useCarrinho, IAddress } from '../../contexts/carrinho';
import { isEmpty, isNotEmpty } from '../../services/util';
import { getCEP, getCidades, getEstados } from '../../util';
import Login from '../Login';

const Etapa2: React.FC = (props) => {
  const [form, setForm] = useState([
    {
      name: 'address_postal_code', label: 'CEP', value: '', isRequired: true, isError: false, mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    },
    {
      name: 'address_street', label: 'Logradouro', value: '', isRequired: true, isError: false,
    },
    {
      name: 'address_number', label: 'Numero', value: '', isRequired: true, isError: false,
    },
    { name: 'address_complement', label: 'Complemento', value: '' },
    {
      name: 'address_district', label: 'Bairro', value: '', isRequired: true, isError: false,
    },
    {
      name: 'address_city', label: 'Cidade', value: '', isRequired: true, isError: false,
    },
    {
      name: 'address_state', label: 'Estado', value: '', isRequired: true, isError: false,
    },
    {
      name: 'address_email', label: 'E-mail para entrega', value: '', isRequired: true, isError: false,
    },
  ]);

  const [isVirtual, setIsVirtual] = useState(false);
  const [isNotVirtual, setIsNotVirtual] = useState(false);

  const [data, setData] = useState<IAddress | null>(null);
  const [isValid, setIsValid] = useState(false);

  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);

  const {
    getCarrinho, qtd, loading, saveAddress, endereco,
  } = useCarrinho();
  const { customer, loginIsValid } = useCadastro();
  const history = useHistory();

  useEffect(() => {
    async function init() {
      const items = await getEstados();
      setEstados(items);
    }

    init();
  }, []);

  useEffect(() => {
    const carrinho = getCarrinho();

    const onlyIsVirtual = carrinho.items.some((i: any) => i.item.is_virtual);
    const onlyIsNotVirtual = carrinho.items.some((i: any) => !i.item.is_virtual);

    setIsVirtual(onlyIsVirtual);
    setIsNotVirtual(onlyIsNotVirtual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstPage = () => {
    history.push('/carrinho');
  };

  useEffect(() => {
    async function checkLogin() {
      if (customer) {
        await loginIsValid();

        let newForm: any = [];

        if (!endereco.address_postal_code) {
          Object.entries(customer).map((d: any) => {
            const [k, v] = d;

            newForm = form.map((f: any) => {
              if (f.name === k) {
                f.value = v;
              }

              if (f.name === 'address_email' && k === 'email') {
                f.value = customer.email;
              }

              return f;
            });
          });
        } else {
          Object.entries(endereco).map((d: any) => {
            const [k, v] = d;

            newForm = form.map((f: any) => {
              if (f.name === k) {
                f.value = v;
              }

              return f;
            });
          });
        }

        setForm([...newForm]);
      }
    }

    checkLogin();
  }, [customer]);

  useEffect(() => {
    const resValid = form.every((f: any) => f.isError === false);
    setIsValid(resValid);
  }, [form]);

  useEffect(() => {
    async function submit() {
      if (isValid && data) {
        await saveAddress(data);
        history.push('/carrinho/pagamento');
      }
    }

    submit();
  }, [isValid]);

  const thirdPage = async (e: FormEvent) => {
    e.preventDefault();

    const data: IAddress = {
      address_postal_code: getField(form, 'address_postal_code').value,
      address_street: getField(form, 'address_street').value,
      address_number: getField(form, 'address_number').value,
      address_complement: getField(form, 'address_complement').value,
      address_district: getField(form, 'address_district').value,
      address_city: getField(form, 'address_city').value,
      address_state: getField(form, 'address_state').value,
      address_email: getField(form, 'address_email').value,
    };

    setData(data);

    Object.entries(data).map((d: any) => {
      const [k, v] = d;

      const item = form.find((f: any) => f.name === k);

      if (item) {
        item.isError = false;

        if (item.isRequired) {
          if (isEmpty(v)) {
            item.isError = true;
          }
        }
      }

      setForm([...form]);
    });
  };

  const onChange = async ({ name, value }: any) => {
    setForm([
      ...form.map((f: any) => {
        if (f.name === name) {
          f.value = value;
        }

        return f;
      }),
    ]);

    if (name === 'address_postal_code' && isNotEmpty(value)) {
      const vlr = value.replace(/[-._]/gu, '');

      if (vlr.length === 8) {
        const items = await getCEP(vlr);

        if (!items.erro) {
          setForm([
            ...form.map((f: any) => {
              if (f.name === 'address_street') {
                f.value = items.logradouro;
              }
              if (f.name === 'address_complement') {
                f.value = items.complemento;
              }
              if (f.name === 'address_district') {
                f.value = items.bairro;
              }
              if (f.name === 'address_state') {
                f.value = items.uf;
              }
              if (f.name === 'address_city') {
                f.value = items.ibge;
              }

              return f;
            }),
          ]);
        }
      }
    }
  };

  const onChangeCombobox = async (e: any) => {
    onChange({ name: e.name, value: e.value });

    if (e.name === 'address_state' && isNotEmpty(e.value)) {
      const items = await getCidades(e.value);
      setCidades(items);
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (qtd === 0) {
    return <Redirect to="/carrinho" />;
  }

  if (!customer) {
    sessionStorage.setItem('goBack', '/carrinho/endereco');
  }

  return (
    <div className="etapa2">
      {!customer && <Login />}

      {customer && (
      <>
        {isVirtual && (
        <div className="linha">
          <div className="coluna">
            <InputField field={getField(form, 'address_email')} onChange={onChange} />
          </div>
        </div>
        )}

        {isNotVirtual && (
        <>
          <div className="linha">
            <div className="coluna">
              <InputField field={getField(form, 'address_postal_code')} onChange={onChange} regex />
            </div>
            <div className="coluna" />
          </div>

          <div className="linha">
            <div className="coluna">
              <InputField field={getField(form, 'address_street')} onChange={onChange} />
            </div>

            <div className="coluna">
              <InputField field={getField(form, 'address_number')} onChange={onChange} />
            </div>
          </div>

          <div className="linha">
            <div className="coluna">
              <InputField field={getField(form, 'address_complement')} onChange={onChange} />
            </div>

            <div className="coluna">
              <InputField field={getField(form, 'address_district')} onChange={onChange} />
            </div>
          </div>

          <div className="linha">
            <div className="coluna">
              <ComboboxField field={getField(form, 'address_state')} onChange={onChangeCombobox} items={estados} />
            </div>

            <div className="coluna">
              <ComboboxField field={getField(form, 'address_city')} onChange={onChangeCombobox} items={cidades} />
            </div>
          </div>
        </>
        )}
      </>
      )}

      {customer && (
      <div className="boxRodape">
        <Button variant="contained" color="primary" onClick={() => firstPage()}>
          Anterior
        </Button>

        <Button variant="contained" color="primary" onClick={(e) => thirdPage(e)}>
          Proxima
        </Button>
      </div>
      )}
    </div>
  );
};

export default Etapa2;
