import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { MdPlayCircleFilled } from 'react-icons/md';
import { Link, Route, useParams } from 'react-router-dom';
import { useCategorias } from '../../contexts/categorias';
import { usePalavras } from '../../contexts/palavras';
import { formatarURL } from '../../services/util';
import './styles.css';

interface Props {
	item: any;
}

const PalavrasBodyItem: React.FC<Props> = ({ item }) => (
  <Link to={`/palavra/${item._id}/${formatarURL(item.title)}`} key={item._id} title={item.title}>
    <div className="item">
      <div
        style={{
					  backgroundImage: `url(${item.url})`,
					  backgroundSize: 'cover',
        }}
      >
        {item.video && <MdPlayCircleFilled className="iconPlay" />}
      </div>

      <div className="title">{item.title.length > 100 ? `${item.title.substr(0, 95)}...` : item.title}</div>

      <div className="iconCategory">
        {item._idCategory?.title}
        <img src={item._idCategory?.url} alt={item._idCategory?.title} title={item._idCategory?.title} />
      </div>
    </div>
  </Link>
);

type PalavrasBodyProps = {
    filter: number;
};

function PalavrasBody({ filter }: PalavrasBodyProps) {
  const { data, loadData, loading } = usePalavras();

  const params = useParams<{ category_id: string }>();

  useEffect(() => {
    loadData(params.category_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.category_id]);

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  if (data.video.length === 0 && data.notvideo.length === 0) {
    return (
      <div className="nenhumRegistro">
        <p>Desculpe, no momento não temos informações cadastradas.</p>
        <p>Confira novamente em breve. :)</p>
        <br />
        <p>Equipe Mulher com Palavra</p>
      </div>
    );
  }

  return (
    <>
      {(filter === 0 || filter === 1) && data.video.length > 0 && (
      <div id="videosList">
        <h3>Vídeos</h3>
        {data.video.map((item: any) => (
          <PalavrasBodyItem item={item} key={item._id} />
        ))}
      </div>
      )}

      {(filter === 0 || filter === 2) && data.notvideo.length > 0 && (
      <div id="textoList">
        <h3>Textos</h3>
        {data.notvideo.map((item: any) => (
          <PalavrasBodyItem item={item} key={item._id} />
        ))}
      </div>
      )}
    </>
  );
}

const Palavras: React.FC = () => {
  const { data, loadData, loading } = useCategorias();
  const [filter, setFilter] = useState(0);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  document.title = 'Palavras e Mensagens | Mulher com Palavra';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Confira nossas palavras que te levarão a ter mais conhecimento da Palavra e intimidade com Deus.';

  return (
    <div id="contentMessages">
      {data.map((item: any) => (
        <div className="linhaCategory" key={item._id}>
          <div className="titleCategory" style={{ backgroundImage: `url(${item.url})` }}>
            <AnchorLink href="#sectionMessage">
              <Link to={`/palavras/${item._id}`} title={item.title}>
                <span>{item.title}</span>
              </Link>
            </AnchorLink>
          </div>
        </div>
      ))}

      <section id="sectionFilter">
        <Button variant="contained" color="secondary" onClick={() => setFilter(0)}>
          Exibir tudo
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setFilter(1)}>
          Exibir somente Videos
        </Button>
        <Button variant="contained" color="secondary" onClick={() => setFilter(2)}>
          Exibir somente Textos
        </Button>
      </section>

      <section id="sectionMessage" style={{ width: '100%' }}>
        <div className="boxMessage">
          <Route path="/palavras/:category_id?" render={(props) => <PalavrasBody {...props} filter={filter} />} />
        </div>
      </section>
    </div>
  );
};

export default Palavras;
