import { isNotEmpty } from './services/util';

const getEstados = async () => {
  let registros = sessionStorage.getItem('MCP-estados') || [];

  if (registros.length === 0) {
    const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
    const data = await response.json();

    const inOrder = data
      .sort((a: any, b: any) => {
        if (a.sigla > b.sigla) {
          return 1;
        }

        if (a.sigla < b.sigla) {
          return -1;
        }

        return 0;
      })
      .map((i: any) => ({
        title: i.nome,
        value: i.sigla,
      }));

    sessionStorage.setItem('MCP-estados', JSON.stringify(inOrder));
    registros = inOrder;
  }

  try {
    return JSON.parse(registros as any);
  } catch {
    return registros;
  }
};

const getCidades = async (uf: any) => {
  let registros = sessionStorage.getItem(`MCP-cidades-${uf}`) || [];

  if (registros.length === 0) {
    const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
    const data = await response.json();

    const inOrder = data
      .sort((a: any, b: any) => {
        if (a.nome > b.nome) {
          return 1;
        }

        if (a.nome < b.nome) {
          return -1;
        }

        return 0;
      })
      .map((i: any) => ({
        title: i.nome,
        value: i.id,
      }));

    sessionStorage.setItem(`MCP-cidades-${uf}`, JSON.stringify(inOrder));
    registros = inOrder;
  }

  try {
    return JSON.parse(registros as any);
  } catch {
    return registros;
  }
};

const getCEP = async (cep: any) => {
  const response = await fetch(`https://viacep.com.br/ws/${cep}/json`);
  return await response.json();
};

const getValidNamePDF = (name: string) => (isNotEmpty(name) ? `${name.replace(/ /gu, '-')}.pdf` : 'test.pdf');

export {
  getCEP, getEstados, getCidades, getValidNamePDF,
};
