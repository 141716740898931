import { Button } from '@material-ui/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect } from 'react';
import { FaReply } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import ShareSocial from '../../components/ShareSocial';
import { usePalavras } from '../../contexts/palavras';
import { getValidNamePDF } from '../../util';
import './styles.css';

const Palavra: React.FC = () => {
  const { dataByID, loadDataByID, loading } = usePalavras();
  const params = useParams<{ message_id: string }>();

  useEffect(() => {
    loadDataByID(params.message_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.message_id]);

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  const printDocument = (data: any) => {
    const input: any = document.querySelector('.description');

    html2canvas(input).then((canvas) => {
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(canvas);

      const width = pdf.internal.pageSize.getWidth();
      const height = (imgProps.height * width) / imgProps.width;

      const widthRatio = width / canvas.width;
      const heightRatio = height / canvas.height;

      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
      const widthLimit = (canvas.width * ratio) - 20;
      const heightLimit = (canvas.height * ratio) - 20;

      pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 10, widthLimit, heightLimit);

      let heightLeft = height;
      const pageHeight = pdf.internal.pageSize.getHeight();
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        const position = (heightLeft - height);

        pdf.addPage();
        pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 10, position, widthLimit, height);
        heightLeft -= pageHeight;
      }

      pdf.save(getValidNamePDF(data.title));
    });
  };

  return (
    <div id="contentMessage">
      <div className="linha">
        <div className="btnVoltar">
          <Link to="/palavras">
            <FaReply className="iconeBack" />
            {' '}
            Voltar
          </Link>
        </div>
        <div className="title">{dataByID.title}</div>
        {dataByID.video && (
        <div className="video">
          <YouTube
            videoId={dataByID.videoID}
            opts={{
							  playerVars: {
							    autoplay: 1,
							  },
            }}
          />
        </div>
        )}
        {dataByID.image && !dataByID.video && <img src={dataByID.url} alt={dataByID.title} title={dataByID.title} />}

        {dataByID.description && dataByID.description.length > 10 && <div className="description" dangerouslySetInnerHTML={{ __html: dataByID.description }} />}

        <ShareSocial shareUrl={window.location.href} title={dataByID.title} />

        {!dataByID.video && (
        <Button variant="contained" color="secondary" onClick={() => printDocument(dataByID)}>
          Clique aqui para baixar em PDF
        </Button>
        )}
      </div>
    </div>
  );
};

export default Palavra;
