const meses = ['Janeiro', 'Fevereiro', 'Marco', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

export const formatarDataHora = (dt: string) => {
  if (!dt || dt === 'Invalid Date') return;

  const vlr = new Date(dt);

  const dia = vlr.getDate().toString();
  // let diaF = dia.length === 1 ? "0" + dia : dia;
  const mes = vlr.getMonth().toString();
  const mesF = mes.length === 1 ? `0${mes}` : mes;
  // let anoF = vlr.getFullYear().toString();

  const hora = vlr.getHours().toString();
  const horaF = hora.length === 1 ? `0${hora}` : hora;
  const minutos = vlr.getMinutes().toString();
  const minutosF = minutos.length === 1 ? `0${minutos}` : minutos;

  if (parseInt(horaF) > 0) {
    if (parseInt(minutosF) > 0) {
      return `${dia} ${meses[parseInt(mesF)].slice(0, 3).toUpperCase()}, ${hora}h${minutosF}`;
    }
    return `${dia} ${meses[parseInt(mesF)].slice(0, 3).toUpperCase()}, ${hora}h`;
  }
  return `${dia} ${meses[parseInt(mesF)].slice(0, 3).toUpperCase()}`;
};

export const formatarValor = (valor: any) => valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

export const formatarURL = (vlr: string) => vlr.toString().replace(/ /gi, '+');

export const isEmpty = (vlr: any) => {
  if (!vlr || vlr === null || vlr === undefined || vlr === 'undefined' || vlr === 0 || vlr === '0') {
    return true;
  }

  return false;
};

export const isNotEmpty = (vlr: any) => !isEmpty(vlr);

export const extArquivo = (vlr: string) => {
  const pos = vlr.lastIndexOf('.');
  const extensao = vlr.substr(pos);
  let tipo;

  switch (extensao) {
    case '.jpeg':
    case '.jpg':
      tipo = 'image/jpeg';
      break;
    case '.png':
      tipo = 'image/png';
      break;
    case '.gif':
      tipo = 'image/gif';
      break;
    case '.pdf':
      tipo = 'application/pdf';
      break;
    case '.zip':
      tipo = 'application/zip';
      break;
    case '.txt':
      tipo = 'text/plain;charset=utf-8';
      break;
    case '.doc':
      tipo = 'application/msword';
      break;
    case '.docx':
      tipo = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    default:
      tipo = 'application/octet-stream';
  }

  return { extensao, tipo };
};
