import React from 'react';

import {
  EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton,
} from 'react-share';

import './styles.css';

interface Props {
	shareUrl: string;
	title: string;
}

const ShareSocial: React.FC<Props> = ({ shareUrl, title }) => (
  <div className="contentShareSocial">
    <FacebookShareButton url={shareUrl} quote={title}>
      <FacebookIcon size={32} round />
    </FacebookShareButton>

    <TwitterShareButton url={shareUrl} title={title}>
      <TwitterIcon size={32} round />
    </TwitterShareButton>

    <EmailShareButton url={shareUrl} subject={title} body="">
      <EmailIcon size={32} round />
    </EmailShareButton>

    <TelegramShareButton url={shareUrl} title={title}>
      <TelegramIcon size={32} round />
    </TelegramShareButton>

    <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
      <WhatsappIcon size={32} round />
    </WhatsappShareButton>
  </div>
);

export default ShareSocial;
