import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdList } from 'react-icons/md';
import { useHeader } from '../../contexts/header';
import { useCadastro } from '../../contexts/cadastro';

import logotipo from '../../assets/logotipo.png';
import './styles.css';
import {
  FaShoppingCart, FaInstagram, FaTelegram, FaYoutube,
} from 'react-icons/fa';
import { useCarrinho } from '../../contexts/carrinho';

interface PropsHeaderButtons {
	path: string;
	title: string;
	cssClass?: string;
	onClickParent?: () => void;
}

const HeaderButtons: React.FC<PropsHeaderButtons> = ({
  path, title, cssClass, onClickParent,
}) => {
  const { toggleMenu } = useHeader();
  const { pathname } = useLocation();

  return (
    <Link
      className={`${cssClass} ${pathname === path ? 'active' : ''}`}
      to={path}
      onClick={() => {
			  toggleMenu();

			  if (onClickParent) {
			    onClickParent();
			  }
      }}
    >
      {title}
    </Link>
  );
};

const Header: React.FC = () => {
  const { open, toggleMenu, setOpen } = useHeader();
  const { logoutCadastro, customer } = useCadastro();
  const { qtd, getCarrinho } = useCarrinho();
  const location = useLocation();

  useEffect(() => {
    getCarrinho();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id="cabecalho">
        <div className="content" onClick={() => setOpen(false)}>
          <Link to="/">
            <img src={logotipo} title="Mulher com Palavra" alt="Mulher com Palavra" className="logotipo" />
          </Link>
          <div className="menuSuperior">
            <HeaderButtons path="/" title="Início" />
            <HeaderButtons path="/sobre" title="Sobre" />
            <HeaderButtons path="/palavras" title="Palavras" />
            <HeaderButtons path="/eventos" title="Eventos" />
            <HeaderButtons path="/equipe" title="Equipe" />
            <HeaderButtons path="/loja" title="Loja" />
            <HeaderButtons path="/contato" title="Contato" />

            {!customer && (
            <>
              <HeaderButtons path="/cadastro" title="Cadastro" />
              <HeaderButtons path="/login" title="Login" />
            </>
            )}

            {customer && (
            <>
              <HeaderButtons path="/perfil" title="Perfil" />

              <HeaderButtons
                path="/"
                title="Sair da conta"
                onClickParent={async () => {
									  await logoutCadastro();
                }}
              />
            </>
            )}

            <div className="iconeRSsuperior">
              <Link to="/carrinho" title="Carrinho">
                <FaShoppingCart className="iconeFooter" />
                <span>
                  <sup>{qtd}</sup>
                </span>
              </Link>

              <Link to={{ pathname: 'https://instagram.com/mulhercompalavra' }} target="_blank" title="Instagram">
                <FaInstagram className="iconeFooter" />
              </Link>

              <Link to={{ pathname: 'https://youtube.com/mulhercompalavra' }} target="_blank" title="YouTube">
                <FaYoutube className="iconeFooter" />
              </Link>

              <Link
                to={{
								  pathname: 'https://t.me/joinchat/Nr3ri1FmorQDbWLY-yuCRA',
                }}
                target="_blank"
                title="Telegram"
              >
                <FaTelegram className="iconeFooter" />
              </Link>
            </div>
          </div>
        </div>

        <div className="iconeRS">
          <Link to="/carrinho" title="Carrinho">
            <FaShoppingCart className="iconeFooter" />
            <span>
              <sup>{qtd}</sup>
            </span>
          </Link>

          <Link to={{ pathname: 'https://instagram.com/mulhercompalavra' }} target="_blank" title="Instagram">
            <FaInstagram className="iconeFooter" />
          </Link>

          <Link to={{ pathname: 'https://youtube.com/mulhercompalavra' }} target="_blank" title="YouTube">
            <FaYoutube className="iconeFooter" />
          </Link>

          <Link
            to={{
						  pathname: 'https://t.me/joinchat/Nr3ri1FmorQDbWLY-yuCRA',
            }}
            target="_blank"
            title="Telegram"
          >
            <FaTelegram className="iconeFooter" />
          </Link>
        </div>

        <MdList onClick={() => toggleMenu()} className="iconeMenu" />

        <div className={`menuDireita ${open ? 'active' : ''} `}>
          <HeaderButtons path="/" title="Início" cssClass="itemMenu" />
          <HeaderButtons path="/sobre" title="Sobre" cssClass="itemMenu" />
          <HeaderButtons path="/palavras" title="Palavras" cssClass="itemMenu" />
          <HeaderButtons path="/eventos" title="Eventos" cssClass="itemMenu" />
          <HeaderButtons path="/equipe" title="Equipe" cssClass="itemMenu" />
          <HeaderButtons path="/loja" title="Loja" cssClass="itemMenu" />
          <HeaderButtons path="/contato" title="Contato" cssClass="itemMenu" />

          {!customer && (
          <>
            <HeaderButtons path="/cadastro" title="Cadastro" cssClass="itemMenu" />

            <HeaderButtons path="/login" title="Login" cssClass="itemMenu" />
          </>
          )}

          {customer && (
          <>
            <HeaderButtons path="/perfil" title="Perfil" cssClass="itemMenu" />

            <HeaderButtons
              path="/"
              title="Sair da conta"
              cssClass="itemMenu"
              onClickParent={async () => {
								  await logoutCadastro();
              }}
            />
          </>
          )}
        </div>
      </div>

      <div style={{ paddingTop: 67 }}>{location.pathname !== '/' && <h2 style={{ textTransform: 'capitalize' }}>{setTitleHeader(location)}</h2>}</div>
    </>
  );
};

function setTitleHeader(location: any) {
  const value = location.pathname.replace('/', '');

  const title = decodeURIComponent(location.pathname.substr(location.pathname.lastIndexOf('/') + 1)).replace(/\+/g, ' ');

  // if (!location.key) {
  // 	return "Pagina não encontrada";
  // } else

  if (value.indexOf('galeria/') > -1) {
    return 'Detalhes do evento';
  } if (value.indexOf('detalhes/') > -1) {
    return title || 'Detalhes do produto';
  } if (value.indexOf('contato') > -1) {
    return 'Fale conosco';
  } if (value.indexOf('cadastro') > -1) {
    return 'Novo cadastro';
  } if (value.indexOf('carrinho/endereco') > -1) {
    return 'Dados para entrega';
  } if (value.indexOf('carrinho/pagamento') > -1) {
    return 'Pagamento';
  } if (value.indexOf('carrinho') > -1) {
    return 'Pedido';
  } if (value.indexOf('palavra') > -1) {
    return 'Palavras';
  }
  return value;
}

export default Header;
