import React, {
  createContext, useContext, useState, ReactNode,
} from 'react';

interface IContextProps {
	open: boolean;
	setOpen: (value: boolean) => void;
	toggleMenu: () => void;
}

const HeaderContext = createContext({} as IContextProps);

interface IProps {
	children: ReactNode;
}

export function HeaderProvider({ children }: IProps) {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <HeaderContext.Provider
      value={{
			  open,
			  toggleMenu,
			  setOpen,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}

export function useHeader() {
  return useContext(HeaderContext);
}
