import React, { useState, FormEvent, useEffect } from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import { FaInstagram, FaYoutube, FaEnvelopeOpenText } from 'react-icons/fa';
import { Button } from '@material-ui/core';
import { useContato } from '../../contexts/contato';
import InputField, { getField } from '../../components/InputField';
import { isEmpty } from '../../services/util';

const Contato: React.FC = () => {
  const [form, setForm] = useState([
    {
      name: 'name', label: 'Nome', value: '', isRequired: true, isError: false,
    },
    {
      name: 'email', label: 'E-mail', value: '', isRequired: true, isError: false,
    },
    {
      name: 'phone', label: 'Telefone', value: '', isRequired: true, isError: false, mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    },
    {
      name: 'message', label: 'Mensagem', value: '', isRequired: true, isError: false,
    },
  ]);

  const { saveContato } = useContato();

  const [data, setData] = useState<any>(null);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const resValid = form.every((f: any) => f.isError === false);
    setIsValid(resValid);
  }, [form]);

  useEffect(() => {
    async function submit() {
      if (isValid && data) {
        await saveContato(data);

        setForm([
          ...form.map((f: any) => {
            f.value = '';

            return f;
          }),
        ]);
      }
    }

    submit();
  }, [isValid]);

  const handleSalvar = async (e: FormEvent) => {
    e.preventDefault();

    const data = {
      name: getField(form, 'name').value,
      email: getField(form, 'email').value,
      phone: getField(form, 'phone').value,
      message: getField(form, 'message').value,
    };

    setData(data);

    Object.entries(data).map((d: any) => {
      const [k, v] = d;

      const item = form.find((f: any) => f.name === k);

      if (item) {
        item.isError = false;

        if (item.isRequired) {
          if (isEmpty(v)) {
            item.isError = true;
          }
        }
      }

      setForm([...form]);
    });
  };

  const onChange = async ({ name, value }: any) => {
    setForm([
      ...form.map((f: any) => {
        if (f.name === name) {
          f.value = value;
        }

        return f;
      }),
    ]);
  };

  document.title = 'Contato | Mulher com Palavra';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Tem alguma dúvida ou recado? Quer nos convidar para algum evento? Entre em contato conosco.';

  return (
    <div id="contentContato">
      <div>
        <form onSubmit={handleSalvar} noValidate autoComplete="off">
          <h3>ENVIE SUA MENSAGEM, NOSSA EQUIPE RESPONDERÁ EM BREVE</h3>

          <div className="linha">
            <div className="coluna">
              <InputField field={getField(form, 'name')} onChange={onChange} />
            </div>

            <div className="coluna">
              <InputField field={getField(form, 'email')} onChange={onChange} />
            </div>
          </div>

          <div className="linha">
            <div className="coluna">
              <InputField field={getField(form, 'phone')} onChange={onChange} regex />
            </div>
          </div>

          <div className="linha">
            <div className="coluna">
              <InputField field={getField(form, 'message')} onChange={onChange} multiline />
            </div>
          </div>

          <div className="boxButton">
            <Button variant="contained" color="secondary" type="submit">
              Enviar
            </Button>
          </div>
        </form>
      </div>

      <div className="btnRodape">
        <div>
          <Link to={{ pathname: 'https://instagram.com/mulhercompalavra' }} target="_blank">
            <FaInstagram className="iconeFooter" />
            Instagram
          </Link>
        </div>
        <div>
          <Link to={{ pathname: 'https://youtube.com/mulhercompalavra' }} target="_blank">
            <FaYoutube className="iconeFooter" />
            Youtube
          </Link>
        </div>
        <div>
          <Link to={{ pathname: 'mailto:contato@mulhercompalavra.com.br' }} target="_blank">
            <FaEnvelopeOpenText className="iconeFooter" />
            E-mail
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contato;
