import React from 'react';
import './styles.css';
import { FaYoutube, FaInstagram, FaTelegram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useHeader } from '../../contexts/header';
import logotipo2 from '../../assets/logotipo2.png';

const Footer: React.FC = () => {
  const { setOpen } = useHeader();

  return (
    <div className="contentFooter" onClick={() => setOpen(false)}>
      <div className="esquerda">
        <Link to="/">
          <img
            src={logotipo2}
            title="Mulher com Palavra"
            alt="Mulher com Palavra"
            className="logotipo"
          />
        </Link>

        <span>REDES SOCIAIS</span>

        <div>
          <Link
            to={{ pathname: 'https://instagram.com/mulhercompalavra' }}
            target="_blank"
            title="Instagram"
          >
            <FaInstagram className="iconeFooter" />
          </Link>

          <Link
            to={{ pathname: 'https://youtube.com/mulhercompalavra' }}
            target="_blank"
            title="YouTube"
          >
            <FaYoutube className="iconeFooter" />
          </Link>

          <Link
            to={{ pathname: 'https://t.me/joinchat/Nr3ri1FmorQDbWLY-yuCRA' }}
            target="_blank"
            title="Telegram"
          >
            <FaTelegram className="iconeFooter" />
          </Link>
        </div>
      </div>

      <div className="direita">
        <div>
          <span>Conheça-nos:</span>
          <Link to="/sobre">Sobre</Link>
          <Link to="/equipe">Equipe</Link>
        </div>

        <div>
          <span>Fique por dentro:</span>
          <Link to="/palavras">Palavras</Link>
          <Link to="/eventos">Eventos</Link>
        </div>

        <div>
          <span>Fale conosco:</span>
          <Link to="/contato">Contato</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
