import React, { useState, useEffect } from 'react';
import './styles.css';
import ReactModal from 'react-modal';
import { Button } from '@material-ui/core';
import { usePedido } from '../../contexts/pedido';
import MoneyFormat from '../../services/money';
import { formatarDataHora, formatarValor } from '../../services/util';

const Pedido: React.FC = () => {
  const {
    data, loadData, loading, cancelar,
  } = usePedido();
  const [showModal, setShowModal] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<any>();

  const [isVirtual, setIsVirtual] = useState(false);
  const [isNotVirtual, setIsNotVirtual] = useState(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentOrder) {
      const onlyIsVirtual = currentOrder.items.some((i: any) => i._idProduct?.is_virtual);
      const onlyIsNotVirtual = currentOrder.items.some((i: any) => !i._idProduct?.is_virtual);

      setIsVirtual(onlyIsVirtual);
      setIsNotVirtual(onlyIsNotVirtual);
    } else {
      setIsVirtual(false);
      setIsNotVirtual(false);
    }
  }, [currentOrder]);

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  if (data.length === 0) {
    return (
      <div className="nenhumRegistro">
        <p>No momento você não possui nenhum pedido realizado.</p>
      </div>
    );
  }

  const calcularTotal = (i: any) => {
    if (i) {
      const { items = [], frete } = i;

      const total = items.reduce((t: any, obj: any) => t + obj.total, 0);

      return MoneyFormat(total + frete);
    }
  };

  document.title = 'Pedido | Mulher com Palavra';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Tem alguma dúvida ou recado? Entre em contato conosco.';

  return (
    <div id="contentPedido">
      {data.length > 0
					&& data.map((i: any) => (
  <div key={i._id}>
    <div>
      <b>Data:</b>
      {' '}
      {formatarDataHora(i.createdAt)}
    </div>

    <div>
      <b>Situação:</b>
      {' '}
      {i._idOrderStatus.title}
    </div>

    {i._idOrderStatus.title !== 'Gratuito' && (
    <div>
      <b>Total:</b>
      {' '}
      {calcularTotal(i)}
    </div>
    )}

    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Button
        variant="contained"
        onClick={() => {
										  setCurrentOrder(i);
										  setShowModal(true);
        }}
      >
        Exibir detalhes
      </Button>

      <Button
        variant="contained"
        style={{ backgroundColor: 'red', color: 'white' }}
        onClick={() => {
										  cancelar(i._id);
        }}
      >
        Cancelar
      </Button>
    </div>
  </div>
					))}

      <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <div className="modalDetalhes">
          <fieldset>
            <legend>Pedido</legend>

            <div>
              <div>
                <b>Data da compra:</b>
                {' '}
                {formatarDataHora(currentOrder?.createdAt)}
              </div>
              <div>
                <b>Situação:</b>
                {' '}
                {currentOrder?._idOrderStatus.title}
              </div>

              {currentOrder?._idOrderStatus.title !== 'Gratuito' && (
              <>
                <div>
                  <b>Valor do frete:</b>
                  {' '}
                  {currentOrder?.frete > 0 ? MoneyFormat(currentOrder?.frete) : 'Gratuito'}
                </div>
                <div>
                  <b>Total:</b>
                  {' '}
                  {calcularTotal(currentOrder)}
                </div>
              </>
              )}
            </div>
          </fieldset>

          {currentOrder?.address_postal_code && (
          <fieldset>
            <legend>Endereço de entrega</legend>

            {isVirtual && (
            <div style={{ marginBottom: 10 }}>
              E-mail:
              {currentOrder?.address_email}
            </div>
            )}

            {isNotVirtual && (
            <>
              <div>
                CEP:
                {currentOrder?.address_postal_code}
              </div>
              <div>
                Logradouro:
                {currentOrder?.address_street}
              </div>
              <div>
                Numero:
                {currentOrder?.address_number}
              </div>
              <div>
                Bairro:
                {currentOrder?.address_district}
              </div>
              <div>
                Complemento:
                {currentOrder?.address_complement}
              </div>
              <div>
                Cidade:
                {currentOrder?.address_city}
              </div>
              <div>
                Estado:
                {currentOrder?.address_state}
              </div>
            </>
            )}
          </fieldset>
          )}

          <fieldset>
            <legend>Itens</legend>

            {currentOrder?.items.map((i: any) => (
              <div className="item" key={i._id}>
                <img src={i._idProduct?.url} alt={i._idProduct?.title} title={i._idProduct?.title} />

                <h4>{i._idProduct?.title}</h4>

                {i._idProduct?.value === 0 && (
                <div>
                  <i>Produto gratuito</i>
                </div>
                )}

                <div style={{ clear: 'both' }}>
                  <br />

                  {i._idProduct?.value > 0 && (
                  <div>
                    <b>Valor unitário:</b>
                    {' '}
                    {formatarValor(i._idProduct?.value)}
                  </div>
                  )}

                  {i._idProduct?.value > 0 && !i._idProduct?.is_virtual && (
                  <div>
                    <b>Quantidade:</b>
                    {i.qtd}
                  </div>
                  )}

                  {i._idProduct?.value > 0 && (
                  <div>
                    <b>Sub-total:</b>
                    {' '}
                    {formatarValor(i._idProduct?.value * i.qtd)}
                  </div>
                  )}

                  {i._idProduct?.is_virtual && <div>Você receberá seu produto digital por e-mail.</div>}
                </div>
              </div>
            ))}
          </fieldset>

          <br />
          <button onClick={() => setShowModal(false)}>Fechar</button>
        </div>
      </ReactModal>
    </div>
  );
};

export default Pedido;
