import React, { useEffect } from 'react';
import './styles.css';
import { useEquipe } from '../../contexts/equipe';

const Equipe: React.FC = () => {
  const { data, loadData, loading } = useEquipe();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  if (data.length === 0) {
    return (
      <div className="nenhumRegistro">
        <p>Desculpe, no momento não temos informações cadastradas.</p>
        <p>Confira novamente em breve. :)</p>
        <br />
        <p>Equipe Mulher com Palavra</p>
      </div>
    );
  }

  document.title = 'Equipe | Mulher com Palavra';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Confira quem faz parte da equipe Mulher com Palavra.';

  return (
    <div id="contentTeam">
      {data.map((item, i) => (
        <div className="linha" key={item._id}>
          <img src={item.url} alt={item.title} title={item.title} />
          <div className="title">{item.title}</div>
          <div className="description" dangerouslySetInnerHTML={{ __html: item.description }} />
        </div>
      ))}
    </div>
  );
};

export default Equipe;
