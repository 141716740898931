import React, {
  createContext, useContext, useState, ReactNode,
} from 'react';
import api from '../services/api';

interface IContextProps {
	data: any;
	dataByID: any;
	loadData: () => void;
	loadDataByID: (value: string) => void;
	loading: boolean;
}

const EventosContext = createContext({} as IContextProps);

interface IProps {
	children: ReactNode;
}

export function EventosProvider({ children }: IProps) {
  const [data, setData] = useState([]);
  const [dataByID, setDataByID] = useState({});
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);

    const res = await api.get('/web/event');

    setData(res.data.items);
    setLoading(false);
  };

  const loadDataByID = async (event_id: string) => {
    setLoading(true);

    const res = await api.get(`/web/event/${event_id}`);

    setDataByID(res.data);
    setLoading(false);
  };

  return (
    <EventosContext.Provider
      value={{
        data, dataByID, loadData, loadDataByID, loading,
      }}
    >
      {children}
    </EventosContext.Provider>
  );
}

export function useEventos() {
  return useContext(EventosContext);
}
