import React from 'react';
import './styles.css';
import Cadastro from '../Cadastro';
import Pedido from '../Pedidos';

const Perfil: React.FC = () => {
  document.title = 'Perfil | Mulher com Palavra';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Tem alguma dúvida ou recado? Entre em contato conosco.';

  return (
    <div className="contentPerfil">
      <div>
        <Cadastro />
      </div>
      <div>
        <Pedido />
      </div>
    </div>
  );
};

export default Perfil;
