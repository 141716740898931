import { Button } from '@material-ui/core';
import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PagSeguro from '../../components/PagSeguro';
import { useCarrinho } from '../../contexts/carrinho';

const Etapa3: React.FC = () => {
  const { loading, qtd } = useCarrinho();
  const history = useHistory();

  const secondPage = () => {
    history.push('/carrinho/endereco');
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (qtd === 0) {
    return <Redirect to="/carrinho" />;
  }

  return (
    <>
      <PagSeguro />

      <Button variant="contained" color="primary" onClick={() => secondPage()}>
        Anterior
      </Button>
    </>
  );
};

export default Etapa3;
