import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useCarrinho } from '../../contexts/carrinho';
import { formatarURL, formatarValor } from '../../services/util';

function CardItem({ i, updateItem, removeItem }: any) {
  const [qtd, setQtd] = useState(1);

  useEffect(() => {
    setQtd(i.qtd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const remover = (i: any) => {
    removeItem(i);
  };

  const delQtd = () => {
    const novoValor = qtd === 1 ? 1 : qtd - 1;

    setQtd(novoValor);
    updateItem(i, novoValor);
  };

  const addQtd = () => {
    const novoValor = qtd + 1;

    setQtd(novoValor);
    updateItem(i, novoValor);
  };

  return (
    <div className="card">
      <div className="esquerda">
        <img src={i.item.url} alt={i.item.title} title={i.item.title} />

        <h4>
          <Link to={`/detalhes/loja/${i.item._id}/${formatarURL(i.item.title)}`}>{i.item.title}</Link>
        </h4>

        {i.item.value > 0 && !i.item.is_virtual && (
        <div>
          Quantidade:
          <Button variant="contained" onClick={() => delQtd()} className="smallBtn">
            -
          </Button>
          <span className="qtd">{qtd}</span>
          <Button variant="contained" onClick={() => addQtd()} className="smallBtn">
            +
          </Button>
        </div>
        )}

        {i.item.value === 0 && (
        <div>
          <i>Produto gratuito</i>
        </div>
        )}

        <div style={{ clear: 'both' }}>
          <br />

          {i.item.value > 0 && (
          <div>
            <b>Valor unitário:</b>
            {' '}
            {formatarValor(i.item.value)}
          </div>
          )}

          {i.item.value > 0 && (
          <div>
            <b>Sub-total:</b>
            {' '}
            {formatarValor(i.item.value * qtd)}
          </div>
          )}

          {i.item.is_virtual && <div>Você receberá seu produto digital por e-mail.</div>}
        </div>
      </div>

      <div className="direita">
        <Button variant="contained" onClick={() => remover(i)} className="remover">
          Remover
        </Button>
      </div>
    </div>
  );
}

const Etapa1: React.FC = () => {
  const {
    getCarrinho, updateCarrinho, loading, qtd,
  } = useCarrinho();
  const [items, setItems] = useState([] as any);
  const [total, setTotal] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const carrinho = getCarrinho();

    const vlrTotal = carrinho.items.reduce((t: number, i: any) => t + i.qtd * i.item.value, 0);

    setTotal(vlrTotal);
    setItems(carrinho.items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const secondPage = () => {
    history.push('/carrinho/endereco');
  };

  const removeItem = (item: any) => {
    const registros = items.filter((i: any) => item.id !== i.id);

    const vlrTotal = registros.reduce((t: number, i: any) => t + i.qtd * i.item.value, 0);

    setTotal(vlrTotal);
    setItems(registros);
    updateCarrinho(registros);
  };

  const updateItem = (i: any, qtd: any) => {
    const registros = items.map((item: any) => {
      if (item.id === i.id) {
        item.qtd = parseInt(qtd);
      }

      return item;
    });

    const vlrTotal = registros.reduce((t: number, i: any) => t + i.qtd * i.item.value, 0);

    setTotal(vlrTotal);
    updateCarrinho(registros);
  };

  const goBack = () => {
    history.push('/loja');
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="etapa1">
      <ul>
        {items.map((i: any, index: any) => (
          <li key={i.id} style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#F5F5F5' }}>
            <CardItem i={i} updateItem={updateItem} removeItem={removeItem} />
          </li>
        ))}
      </ul>

      <Button variant="contained" color="primary" onClick={() => goBack()}>
        Anterior
      </Button>

      {qtd > 0 && (
      <div className="btnNext">
        <Button variant="contained" color="primary" onClick={() => secondPage()}>
          Proxima
        </Button>
      </div>
      )}

      {total > 0 && (
      <div className="total">
        Total do pedido:
        {formatarValor(total)}
      </div>
      )}
    </div>
  );
};

export default Etapa1;
