import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { Button, CircularProgress } from '@material-ui/core';
import { useCadastro } from '../../contexts/cadastro';
import { useCarrinho } from '../../contexts/carrinho';
import api from '../../services/api';
import { formatarValor, isEmpty, isNotEmpty } from '../../services/util';
import ComboboxField from '../ComboboxField';
import InputField, { getField } from '../InputField';

declare global {
	interface Window {
		PagSeguroDirectPayment: any;
	}
}

const PagSeguro: React.FC = () => {
  const {
    getCarrinho, delCarrinho, getSessionID, sessionId,
  } = useCarrinho();
  const { customer } = useCadastro();
  const pathBandeiras = 'https://stc.pagseguro.uol.com.br';

  const [form, setForm] = useState([
    {
      name: 'frete', label: 'Selecione o frete desejado', value: '', isRequired: false, isError: false,
    },
    {
      name: 'tipo', label: 'Forma de pagamento', value: '', isRequired: false, isError: false,
    },
    {
      name: 'banco', label: 'Selecione o banco desejado', value: '', isRequired: false, isError: false,
    },
    {
      name: 'pgto', label: 'Selecione a parcela desejada', value: '', isRequired: false, isError: false,
    },
  ]);

  const [formCartao, setFormCartao] = useState([
    {
      name: 'cardNumber', label: 'Numero', value: '', isRequired: true, mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    },
    {
      name: 'name', label: 'Nome', value: '', isRequired: true, isError: false,
    },
    {
      name: 'brand', label: 'Bandeira', value: '', isRequired: true, isError: false,
    },
    {
      name: 'cvv', label: 'CVV', value: '', isRequired: true, mask: [/\d/, /\d/, /\d/],
    },
    {
      name: 'cpf', label: 'CPF', value: '', isRequired: true, mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    },
    {
      name: 'dtNasc', label: 'Data de nascimento', value: '', isRequired: true, isError: false,
    },
    {
      name: 'expirationMonth', label: 'Data de validade (mes)', value: '', isRequired: true, mask: [/\d/, /\d/],
    },
    {
      name: 'expirationYear', label: 'Data de validade (ano)', value: '', isRequired: true, mask: [/\d/, /\d/, /\d/, /\d/],
    },
    {
      name: 'token', label: '', value: '', isRequired: true, isError: false,
    },
  ]);

  const getFormValue = (formulario: any, field: any) => {
    const item = formulario.find((c: any) => c.name === field);

    if (item) {
      return item.value;
    }

    return '';
  };

  const [onlyFree, setOnlyFree] = useState(false);

  const [pedidoFinalizado, setPedidoFinalizado] = useState(false);
  const [loadingFinalizar, setLoadingFinalizar] = useState(false);
  const [loadingBoleto, setLoadingBoleto] = useState(false);
  const [loadingDebitoOnline, setLoadingDebitoOnline] = useState(false);
  const [loadingCartaoCreditoFinish, setLoadingCartaoCreditoFinish] = useState(false);

  const [tipoFormaPgto, setTipoFormaPgto] = useState('');
  const [pgto, setPgto] = useState(null);
  const [loadingSession, setLoadingSession] = useState(true);
  const [loadingFrete, setLoadingFrete] = useState(null as any);
  const [optFrete, setOptFrete] = useState(null as any);
  const [, setFrete] = useState(0);
  const [brand, setBrand] = useState('');
  const [banco, setBanco] = useState('');
  const [retBoleto, setRetBoleto] = useState(null as any);
  const [retDebitoOnline, setRetDebitoOnline] = useState(null as any);
  const [retCartaoCredito, setRetCartaoCredito] = useState();
  const [installments, setInstallments] = useState([]);
  const [bandeiras, setBandeiras] = useState([] as any);
  const history = useHistory();

  const colTipoPagamento = [
    { title: 'Cartão de crédito', value: 'cartao_credito' },
    { title: 'Débito online', value: 'debito_online' },
    { title: 'Boleto bancário', value: 'boleto' },
  ];

  const getFrete = () => {
    const col: any = [];

    if (optFrete) {
      optFrete.forEach((f: any) => {
        let title = '';

        if (f.error) {
          title = `${f.name} - ${f.error}`;
        } else {
          title = `${f.name} - ${formatarValor(parseFloat(f.price))}`;
        }

        col.push({ title, value: f.price, disabled: !!f.error });
      });
    }

    return col;
  };

  const getColParcelas = (installments: any) => {
    const col: any = [];

    installments.forEach((item: any, i: any) => {
      const qtd = item.quantity;
      const uni = parseFloat(item.installmentAmount.toFixed(2));
      const tot = parseFloat(item.totalAmount.toFixed(2));
      const title = `${qtd} x de ${formatarValor(uni)} - Total de ${formatarValor(tot)}`;

      col.push({ title, value: qtd });
    });

    return col;
  };

  const [pedido, setPedido] = useState({
    id: null,
    total: 0,
    frete: 0,
    produtos: [],
    cliente: {} as any,
    endereco: {} as any,
  });

  useEffect(() => {
    if (customer) {
      const carrinho = getCarrinho();

      const vlrTotal = carrinho.items.reduce((t: number, i: any) => t + i.qtd * i.item.value, 0);

      const ddd = customer.phone ? customer.phone.substr(1, 2) : '';
      const tel = customer.phone ? customer.phone.substr(5) : '';

      setPedido({
        ...pedido,
        id: carrinho.id,
        total: vlrTotal,
        produtos: carrinho.items,
        endereco: {
          cidade: carrinho.endereco?.address_city,
          complemento: carrinho.endereco?.address_complement,
          bairro: carrinho.endereco?.address_district,
          numero: carrinho.endereco?.address_number,
          cep: carrinho.endereco?.address_postal_code,
          estado: carrinho.endereco?.address_state,
          logradouro: carrinho.endereco?.address_street,
          email: carrinho.endereco?.address_email,
        },
        cliente: {
          _id: customer._id,
          nome: customer.name,
          cpf: customer.cpf,
          dtNasc: customer.dtNasc,
          email: customer.email,
          ddd,
          tel,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (pedido.produtos.length > 0 && pedido.produtos.filter((p: any) => !p.item.is_virtual && p.item.value > 0).length > 0) {
      const items = pedido.produtos;

      const justFree = items.every((i: any) => i.item.value === 0);
      setOnlyFree(justFree);

      if (!justFree && items.length > 0) {
        setLoadingFrete(true);
        calcularFrete();
      } else {
        setLoadingFrete(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedido.produtos]);

  useEffect(() => {
    if (isEmpty(sessionId)) {
      getSessionID();
    }

    if (isNotEmpty(sessionId)) {
      window.PagSeguroDirectPayment.setSessionId(sessionId);

      window.PagSeguroDirectPayment.getPaymentMethods({
        amount: pedido.total,
        success(response: any) {
          const images = {
            boleto: '',
            credit: [] as any,
            debit: [] as any,
          };

          if (response.paymentMethods.BOLETO) {
            images.boleto = pathBandeiras + response.paymentMethods.BOLETO.options.BOLETO.images.MEDIUM.path;
          }

          if (Object.keys(response.paymentMethods.CREDIT_CARD.options).length > 0) {
            Object.keys(response.paymentMethods.CREDIT_CARD.options).forEach((i: any) => {
              images.credit.push({
                img: pathBandeiras + response.paymentMethods.CREDIT_CARD.options[i].images.MEDIUM.path,
                name: response.paymentMethods.CREDIT_CARD.options[i].name,
              });
            });
          }

          if (Object.keys(response.paymentMethods.ONLINE_DEBIT.options).length > 0) {
            Object.keys(response.paymentMethods.ONLINE_DEBIT.options).forEach((i: any) => {
              images.debit.push({
                img: pathBandeiras + response.paymentMethods.ONLINE_DEBIT.options[i].images.MEDIUM.path,
                name: response.paymentMethods.ONLINE_DEBIT.options[i].name,
              });
            });
          }

          setBandeiras(images);
          setLoadingSession(false);
        },
        error(response: any) {
          setLoadingSession(false);
          console.log(response);
        },
      });
    } else {
      setLoadingSession(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (isNotEmpty(getFormValue(formCartao, 'brand'))) {
      window.PagSeguroDirectPayment.getInstallments({
        amount: pedido.total,
        brand: getFormValue(formCartao, 'brand'),
        success(response: any) {
          setInstallments(response.installments[getFormValue(formCartao, 'brand')]);
        },
        error(response: any) {
          console.log(response);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  const calcularFrete = async () => {
    const options = {
      cep: pedido.endereco.cep,
      produtos: pedido.produtos
        .filter((p: any) => p.item.value > 0)
        .map((p: any) => ({
          _id: p.item.id,
          unit: p.qtd,
        })),
    };

    const { data } = await api.post('/web/pagseguro/frete', options);

    if (data) {
      setOptFrete(data);
    }

    setLoadingFrete(false);
  };

  const finalizar = async () => {
    const infoCartao: any = {
      cardNumber: '',
      name: '',
      brand: '',
      cvv: '',
      cpf: '',
      dtNasc: '',
      expirationMonth: '',
      expirationYear: '',
      token: '',
    };

    let infoParcela = null;

    if (['debito_online', 'cartao_credito'].includes(tipoFormaPgto)) {
      formCartao.forEach((c: any) => {
        if (c.name === 'cardNumber') {
          c.value = c.value.replace(/ /g, '');
        }

        infoCartao[c.name] = c.value;
      });

      infoParcela = pgto;
    }

    const data = {
      hash: onlyFree ? null : window.PagSeguroDirectPayment.getSenderHash(),
      cartao: isNotEmpty(infoCartao.cardNumber) ? infoCartao : {},
      parcela: infoParcela,
      banco: tipoFormaPgto === 'debito_online' ? banco : null,
      pedido,
      tipoFormaPgto,
    };

    const ret = await api.post('/web/pagseguro/pedido', data);

    if (tipoFormaPgto === 'cartao_credito' && ret.data.name === 'Error') {
      swal('As informações fornecidas para o cartão estão inválidas.', '', 'warning');
      setLoadingCartaoCreditoFinish(false);
      return;
    }

    if (ret.data.message) {
      console.log(ret.data.message);
      swal('Ocorreu um erro na geração do pedido, tente novamente mais tarde', '', 'warning');
      setLoadingBoleto(false);
      return;
    }

    if (tipoFormaPgto === 'boleto') {
      setRetBoleto(ret.data.transaction);
      setLoadingBoleto(false);
    }

    if (tipoFormaPgto === 'debito_online') {
      window.open(ret.data.transaction.paymentLink[0], '_blank');

      setRetDebitoOnline(ret.data.transaction);
      setLoadingDebitoOnline(false);
    }

    if (tipoFormaPgto === 'cartao_credito') {
      setRetCartaoCredito(ret.data.transaction);
      setLoadingCartaoCreditoFinish(false);
    }

    setPedidoFinalizado(true);
    setLoadingFinalizar(false);

    if (ret.data.gratuito) {
      delCarrinho();
      swal('Em instantes você receberá um e-mail com a confirmação do seu pedido', '', 'success');
      history.push('/');
    }

    setTimeout(() => {
      delCarrinho();
      history.push('/');
    }, 15000);
  };

  const getBrand = () => {
    if (getFormValue(formCartao, 'cardNumber').length === 19) {
      window.PagSeguroDirectPayment.getBrand({
        cardBin: getFormValue(formCartao, 'cardNumber').replace(/ /g, '').substring(0, 6),
        success(response: any) {
          handleChangeCartao({ name: 'brand', value: response.brand.name });
          setBrand(response.brand.name);
        },
        error(response: any) {
          handleChangeCartao({ name: 'brand', value: '' });
          setBrand('');
        },
      });
    }
  };

  const getPgto = () => {
    if (isNotEmpty(getFormValue(formCartao, 'cardNumber')) && isNotEmpty(getFormValue(formCartao, 'brand')) && isNotEmpty(getFormValue(formCartao, 'cvv')) && isNotEmpty(getFormValue(formCartao, 'expirationMonth')) && isNotEmpty(getFormValue(formCartao, 'expirationYear'))) {
      window.PagSeguroDirectPayment.createCardToken({
        cardNumber: getFormValue(formCartao, 'cardNumber').replace(/ /g, ''),
        brand: getFormValue(formCartao, 'brand'),
        cvv: getFormValue(formCartao, 'cvv'),
        expirationMonth: getFormValue(formCartao, 'expirationMonth'),
        expirationYear: getFormValue(formCartao, 'expirationYear'),
        success(response: any) {
          handleChangeCartao({ name: 'token', value: response.card.token });
        },
        error(response: any) {
          handleChangeCartao({ name: 'token', value: '' });
        },
      });
    }
  };

  const handleChangeCartao = async ({ name, value }: any) => {
    setFormCartao([
      ...formCartao.map((f: any) => {
        if (f.name === name) {
          f.value = value;
        }

        return f;
      }),
    ]);
  };

  const selectFrete = (value: any) => {
    setFrete(value);

    setPedido({
      ...pedido,
      frete: value,
    });
  };

  const selectPgto = (value: any) => {
    if (parseInt(value)) {
      const item = installments.filter((i: any) => i.quantity === parseInt(value));
      setPgto(item[0]);
    } else {
      setPgto(null);
    }
  };

  useEffect(() => {
    if (pgto) {
      getPgto();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pgto]);

  if (loadingSession) {
    return <div style={{ textAlign: 'center', marginTop: 10 }}>Aguarde, estamos processando seu pedido...</div>;
  }

  if (loadingFrete) {
    return <div style={{ textAlign: 'center', marginTop: 10 }}>Aguarde, estamos processando o frete...</div>;
  }

  if (pedido.total + pedido.frete === 0) {
    return (
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <p>Clique no botão abaixo para processar seu pedido.</p>

        <Button
          variant="contained"
          disabled={loadingFinalizar}
          style={{
            backgroundColor: loadingFinalizar ? 'gray' : 'green', color: 'white', width: 200, marginTop: 40, marginBottom: 30,
          }}
          onClick={() => {
					  setLoadingFinalizar(true);
					  finalizar();
          }}
        >
          {loadingFinalizar ? <CircularProgress /> : 'Finalizar'}
        </Button>
      </div>
    );
  }

  const onChange = async ({ name, value }: any) => {
    setForm([
      ...form.map((f: any) => {
        if (f.name === name) {
          f.value = value;
        }

        return f;
      }),
    ]);
  };

  const onChangeCombobox = async (e: any) => {
    onChange({ name: e.name, value: e.value });

    if (e.name === 'tipo' && isNotEmpty(e.value)) {
      setTipoFormaPgto(e.value);
    }

    if (e.name === 'banco' && isNotEmpty(e.value)) {
      setBanco(e.value);
    }

    if (e.name === 'pgto' && isNotEmpty(e.value)) {
      selectPgto(e.value);
    }

    if (e.name === 'frete' && isNotEmpty(e.value)) {
      selectFrete(parseFloat(e.value));
    }
  };

  return (
    <div className="etapa3">
      <div style={{ display: 'flex', margin: 10, flexDirection: 'column' }}>
        <fieldset>
          <legend>Pedido</legend>

          {pedido.produtos.filter((p: any) => !p.item.is_virtual && p.item.value > 0).length > 0 && (
          <>
            <ComboboxField field={getField(form, 'frete')} onChange={onChangeCombobox} items={getFrete()} />

            {pedido.total > 0 && (
            <>
              <div style={{ marginTop: 10 }}>
                <b>Subtotal: </b>
                {formatarValor(pedido.total)}
              </div>

              {pedido.frete > 0 && (
              <div>
                <b>Frete: </b>
                {formatarValor(pedido.frete)}
              </div>
              )}
            </>
            )}
          </>
          )}

          <div>
            <b>Total: </b>
            {' '}
            {formatarValor(pedido.total + pedido.frete)}
          </div>
        </fieldset>
      </div>

      <div style={{
        display: 'flex', margin: 10, alignItems: 'center', flexDirection: 'column',
      }}
      >
        {onlyFree && !pedidoFinalizado && (
        <button
          disabled={loadingFinalizar}
          style={{
            backgroundColor: loadingFinalizar ? 'gray' : 'green', color: 'white', width: 200, marginTop: 40, marginBottom: 30,
          }}
          onClick={() => {
							  setLoadingFinalizar(true);
							  finalizar();
          }}
        >
          {loadingFinalizar ? <CircularProgress /> : 'Processar'}
        </button>
        )}

        {!onlyFree && (
        <>
          {!pedidoFinalizado && (pedido.frete > 0 || pedido.produtos.filter((p: any) => !p.item.is_virtual && p.item.value > 0).length === 0) && (
          <fieldset>
            <legend>Tipo de pagamento</legend>

            <div className="linha">
              <div className="coluna">
                <ComboboxField field={getField(form, 'tipo')} onChange={onChangeCombobox} items={colTipoPagamento} />
              </div>
            </div>

            {tipoFormaPgto === 'boleto' && !retBoleto && (
            <div className="linha">
              <div className="coluna" style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  disabled={loadingBoleto}
                  style={{
                    backgroundColor: loadingBoleto ? 'gray' : 'green', color: 'white', width: 200, marginTop: 40, marginBottom: 30,
                  }}
                  onClick={() => {
													  setLoadingBoleto(true);
													  finalizar();
                  }}
                >
                  {loadingBoleto ? <CircularProgress /> : 'Gerar um boleto'}
                </Button>
              </div>
            </div>
            )}

            {tipoFormaPgto === 'debito_online' && !retDebitoOnline && (
            <>
              {bandeiras.debit.length > 0 && (
              <div className="linha" style={{ marginTop: 10 }}>
                <div className="coluna">
                  <ComboboxField
                    field={getField(form, 'banco')}
                    onChange={onChangeCombobox}
                    items={bandeiras.debit.map((b: any) => ({ title: b.name.replace('_', ' '), value: b.name }))}
                  />
                </div>
              </div>
              )}

              <div className="linha">
                <div className="coluna" style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    disabled={loadingDebitoOnline || isEmpty(getFormValue(form, 'banco'))}
                    style={{
                      backgroundColor: loadingDebitoOnline || isEmpty(getFormValue(form, 'banco')) ? 'gray' : 'green', color: 'white', width: 200, marginTop: 10,
                    }}
                    onClick={() => {
														  finalizar();
														  setLoadingDebitoOnline(true);
                    }}
                  >
                    {loadingDebitoOnline ? <CircularProgress /> : 'Processar'}
                  </Button>
                </div>
              </div>
            </>
            )}
          </fieldset>
          )}

          {!pedidoFinalizado && tipoFormaPgto === 'cartao_credito' && (
          <div className="linha" style={{ marginTop: 10, width: '100%' }}>
            <fieldset>
              <legend>Cartão de crédito</legend>
              <div className="linha" style={{ marginTop: 10 }}>
                <div className="coluna">
                  <InputField field={getField(formCartao, 'cardNumber')} onChange={handleChangeCartao} onBlur={getBrand} regex />
                </div>
              </div>
              <div className="linha" style={{ marginTop: 10 }}>
                <div className="coluna">
                  <InputField field={getField(formCartao, 'name')} onChange={handleChangeCartao} />
                </div>
              </div>
              <div className="linha" style={{ marginTop: 10 }}>
                <div className="coluna">
                  <InputField field={getField(formCartao, 'cpf')} onChange={handleChangeCartao} regex />
                </div>
              </div>
              <div className="linha" style={{ marginTop: 10 }}>
                <div className="coluna">
                  <InputField field={getField(formCartao, 'dtNasc')} type="date" onChange={handleChangeCartao} />
                </div>
              </div>
              <div className="linha" style={{ marginTop: 10 }}>
                <div className="coluna">
                  <InputField field={getField(formCartao, 'expirationMonth')} onChange={handleChangeCartao} regex onBlur={getPgto} />
                </div>
              </div>
              <div className="linha" style={{ marginTop: 10 }}>
                <div className="coluna">
                  <InputField field={getField(formCartao, 'expirationYear')} onChange={handleChangeCartao} regex onBlur={getPgto} />
                </div>
              </div>
              <div className="linha" style={{ marginTop: 10 }}>
                <div className="coluna">
                  <InputField field={getField(formCartao, 'cvv')} onChange={handleChangeCartao} regex onBlur={getPgto} />
                </div>
              </div>
              {installments.length > 0 && (
              <div className="linha" style={{ marginTop: 10 }}>
                <div className="coluna">
                  <ComboboxField field={getField(form, 'pgto')} onChange={onChangeCombobox} items={getColParcelas(installments)} />
                </div>
              </div>
              )}

              {!retCartaoCredito && (
              <div className="linha" style={{ marginTop: 10 }}>
                <div className="coluna" style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    disabled={loadingCartaoCreditoFinish || isEmpty(getFormValue(form, 'pgto'))}
                    style={{
                      backgroundColor: loadingCartaoCreditoFinish || isEmpty(getFormValue(form, 'pgto')) ? 'gray' : 'green', color: 'white', width: 200, marginTop: 10,
                    }}
                    onClick={() => {
														  setLoadingCartaoCreditoFinish(true);
														  finalizar();
                    }}
                  >
                    {loadingCartaoCreditoFinish ? <CircularProgress /> : 'Processar'}
                  </Button>
                </div>
              </div>
              )}
            </fieldset>
          </div>
          )}
        </>
        )}

        {pedidoFinalizado && (
        <>
          {retBoleto && (
          <Button
            variant="contained"
            style={{ margin: 10 }}
            onClick={() => {
									  window.open(retBoleto.paymentLink[0], '_blank');
            }}
          >
            <img alt="" src={bandeiras.boleto} style={{ width: 50, height: 20, marginRight: 5 }} />
            Visualizar o boleto
          </Button>
          )}

          {retDebitoOnline && (
          <Link to={{ pathname: retDebitoOnline.paymentLink[0] }} target="_blank" title="Débito online">
            Abrir o website do banco
          </Link>
          )}

          {retBoleto && (
          <div>O pedido será concluído, apenas após a confirmação do pagamento.</div>
          )}

          <div>Em instantes você receberá um e-mail com a confirmação do seu pedido</div>
          <div>Obrigado por comprar conosco</div>
        </>
        )}
      </div>
    </div>
  );
};

export default PagSeguro;
