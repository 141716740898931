import React, {
  createContext, useEffect, useContext, useState, ReactNode,
} from 'react';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import api from '../services/api';

export interface Customer {
	_id?: string;
	name: string;
	email: string;
	password: string;
	cpf: string;
	phone: string;
	dtNasc: string;
	address_postal_code: string;
	address_street: string;
	address_number: string;
	address_complement: string;
	address_district: string;
	address_city: string;
	address_state: string;
	address_email?: string;
}

interface IContextProps {
	logoutCadastro: () => void;
	loginCadastro: (value: object) => Promise<boolean>;
	saveCadastro: (value: object) => void;
	setCustomer: (value: any) => void;
	loginIsValid: () => void;
	loading: boolean;
	customer: Customer | null;
}

const CadastroContext = createContext({} as IContextProps);

interface IProps {
	children: ReactNode;
}

export function CadastroProvider({ children }: IProps) {
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['MCP_CUSTOMER', 'MCP_PAGSEGURO']);

  useEffect(() => {
    const storagedCustomer = sessionStorage.getItem('mcp-customer');
    const storagedToken = sessionStorage.getItem('mcp-customer-token');

    if (storagedCustomer && storagedToken) {
      setCustomer(JSON.parse(storagedCustomer));
    }

    setLoading(false);
  }, []);

  const saveCadastro = async (data: any) => {
    setLoading(true);

    if (data._id === '') {
      delete data._id;
    }

    if (data._id) {
      await api.put(`/web/customer/${data._id}`, data);
    } else {
      console.log(data);
      await api.post('/web/customer', data);
    }

    const storagedCustomer = sessionStorage.getItem('mcp-customer');
    const storagedToken = sessionStorage.getItem('mcp-customer-token');

    console.log(storagedCustomer);
    if (storagedCustomer && storagedToken) {
      setCustomer(data);
      sessionStorage.setItem('mcp-customer', JSON.stringify(data));
    }

    swal('Cadastro salvo com sucesso', '', 'success');

    setLoading(false);
  };

  const loginCadastro = async (form: object): Promise<boolean> => {
    setLoading(true);

    console.log(form);
    const { data } = await api.post('/web/login', form);
    console.log(data);

    if (!data || data.error) {
      toast(data ? data.error : 'Servidor fora do ar', {
        position: toast.POSITION.BOTTOM_RIGHT,
        type: toast.TYPE.ERROR,
      });

      setLoading(false);
      return false;
    }

    const { token } = data;

    const dtCookie = new Date();
    dtCookie.setHours(dtCookie.getHours() + 2);
    setCookie('MCP_CUSTOMER', token, { expires: dtCookie });

    const base64 = token.split('.')[1];
    const jwtData = JSON.parse(window.atob(base64));

    const customer = jwtData.item;
    setCustomer(customer);
    console.log(customer);

    sessionStorage.setItem('mcp-customer', JSON.stringify(customer));
    sessionStorage.setItem('mcp-customer-token', token);
    setLoading(false);
    return true;
  };

  const loginIsValid = async () => await api.get('/web/loginIsValid');

  const logoutCadastro = async () => {
    setLoading(true);

    if (customer) {
      await api.get('/web/logout');
    }

    removeCookie('MCP_CUSTOMER');
    removeCookie('MCP_PAGSEGURO');
    sessionStorage.removeItem('mcp-customer');
    sessionStorage.removeItem('mcp-customer-token');
    setCustomer(null);
    setLoading(false);
  };

  return (
    <CadastroContext.Provider
      value={{
			  saveCadastro,
			  loginCadastro,
			  logoutCadastro,
			  setCustomer,
			  loginIsValid,
			  customer,
			  loading,
      }}
    >
      {children}
    </CadastroContext.Provider>
  );
}

export function useCadastro() {
  return useContext(CadastroContext);
}
