import React, {
  ReactNode,
  createContext, useContext, useState,
} from 'react';
import api from '../services/api';

interface IContextProps {
	data: any;
	dataByID: any;
	dataByFilter: any;
	loadData: (value?: string) => void;
	loadDataByID: (value: string) => void;
	loadDataByFilter: () => void;
	loading: boolean;
}

const PalavrasContext = createContext({} as IContextProps);

interface IProps {
	children: ReactNode;
}

export function PalavrasProvider({ children }: IProps) {
  const [data, setData] = useState<any>({ video: [], notvideo: [] });
  const [dataByFilter, setDataByFilter] = useState({ video: [], notvideo: [] });
  const [dataByID, setDataByID] = useState({});
  const [loading, setLoading] = useState(false);

  const loadData = async (category_id: string | undefined) => {
    setLoading(true);
    let res;

    if (category_id) {
      res = await api.get(`/web/ministry/category/${category_id}?qtd=999`);
    } else {
      res = await api.get('/web/ministry?qtd=50');
    }

    const items = {
      video: [...res.data.items.filter((i: any) => i.video !== '')],
      notvideo: [...res.data.items.filter((i: any) => i.video === '')],
    };

    setData(items);
    setLoading(false);
  };

  const loadDataByID = async (message_id: string) => {
    setLoading(true);

    const res = await api.get(`/web/ministry/${message_id}`);
    let videoID;

    if (res.data.video) {
      if (res.data.video.indexOf('youtu.be') > -1) {
        videoID = res.data.video.replace('https://youtu.be/', '');
      } else {
        const regex = /\?+([^=]+)=([^&]+)/gi;
        videoID = res.data.video.match(regex)[0].substr(3);
      }
    }

    setDataByID({ ...res.data, videoID });
    setLoading(false);
  };

  const loadDataByFilter = async () => {
    setLoading(true);

    const resVideo = await api.post('/web/ministry/withfilter', {
      filter: 'video',
    });

    const resNotVideo = await api.post('/web/ministry/withfilter', {
      filter: 'notvideo',
    });

    setDataByFilter({
      video: [...resVideo.data.items] as any,
      notvideo: [...resNotVideo.data.items] as any,
    });

    setLoading(false);
  };

  return (
    <PalavrasContext.Provider
      value={{
			  data,
			  loadData,
			  loading,
			  loadDataByID,
			  dataByID,
			  dataByFilter,
			  loadDataByFilter,
      }}
    >
      {children}
    </PalavrasContext.Provider>
  );
}

export function usePalavras() {
  return useContext(PalavrasContext);
}
