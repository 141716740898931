import React, { useEffect } from 'react';
import './styles.css';
import { useTestemunhos } from '../../contexts/testemunhos';

const Testemunhos: React.FC = () => {
  const { data, loadData, loading } = useTestemunhos();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  if (data.length === 0) {
    return (
      <div className="nenhumRegistro">
        <p>Desculpe, no momento não temos informações cadastradas.</p>
        <p>Confira novamente em breve. :)</p>
        <br />
        <p>Equipe Mulher com Palavra</p>
      </div>
    );
  }

  return (
    <div id="contentTestimony">
      {data.map((item: any) => (
        <div className="linha" key={item._id}>
          <img src={item.url} alt={item.name} title={item.name} />
          <div className="title">{item.name}</div>
          <div className="description" dangerouslySetInnerHTML={{ __html: item.message }} />
        </div>
      ))}
    </div>
  );
};

export default Testemunhos;
