import React, { useEffect } from 'react';
import './styles.css';
import { useSobre } from '../../contexts/sobre';

const Sobre: React.FC = () => {
  const { data, loadData, loading } = useSobre();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  if (data.length === 0) {
    return (
      <div className="nenhumRegistro">
        <p>Desculpe, no momento não temos informações cadastradas.</p>
        <p>Confira novamente em breve. :)</p>
        <br />
        <p>Equipe Mulher com Palavra</p>
      </div>
    );
  }

  document.title = 'Nossa história | Mulher com Palavra';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Conheça a história completa de como surgiu o projeto Mulher com Palavra.';

  return (
    <div id="contentAbout">
      {data.map((item, i) => (
        <div className="linha" key={item._id}>
          <div className="coluna" style={i % 2 === 0 ? { order: 1 } : { order: 2 }}>
            <div className="title">{item.title}</div>
            <div className="description" dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
          <div className="coluna" style={i % 2 === 0 ? { order: 2 } : { order: 1 }}>
            <img src={item.url} alt={item.title} title={item.title} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Sobre;
