import React from 'react';
import './styles.css';

import ReactPlayer from 'react-player';
import deb1 from '../../assets/landingpage/d1.png';
import deb2 from '../../assets/landingpage/d2.png';
// import caderno from "../../assets/landingpage/caderno.jpg";
import devocional from '../../assets/landingpage/devocional-mp.png';
import lobos from '../../assets/landingpage/thiagolobos.png';

const LandingPage: React.FC = () => {
  const letsGo = () => {
    window.open('https://pay.hotmart.com/A53355727K?off=mx08t9hx&split=5');
  };

  document.title = 'Fortalecendo sua saúde emocional';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Fortaleça Sua Saúde Emocional. Mais De 10 Aulas a Sua Disposição. Assista quantas vezes Desejar e a hora que quiser.';

  return (
    <>
      <link href="https://fonts.googleapis.com/css?family=Dosis:300,500,700|Just+Another+Hand|Lato:%27100italic%27,%27300italic%27,%27400italic%27,%27700italic%27,%27900italic%27,100,300,400,700,900|Lora:%27400italic%27,%27700italic%27,400,700|Montserrat:300,400,500,700|Oswald:300,400,700|Open+Sans:%27300italic%27,%27400italic%27,%27600italic%27,%27700italic%27,300,400,600,700|Open+Sans+Condensed:%27300italic%27,300,700|Roboto:%27100italic%27,%27300italic%27,%27400italic%27,%27500italic%27,%27700italic%27,%27900italic%27,100,300,400,500,700,900|Raleway:%27300italic%27,%27400italic%27,%27700italic%27,300,400,700|Nunito:%27wght@200%27,400,700,800|Vollkorn:%27ital,,400,500,600,700,800,900,wght@0," rel="stylesheet" />

      <div className="container">
        <div className="center">
          <div className="box1">
            <h1 className="foto">
              <img alt="" src={deb1} width="100%" />
            </h1>

            <span className="titulo">
              Aulas disponíveis comigo para
              {' '}
              <b>fortalecer sua saúde emocional</b>
              .
              <b>Mais de 10 aulas</b>
              {' '}
              a sua disposição, para você assistir quantas vezes desejar e
              <b>a hora que quiser</b>
              .
            </span>

            <button
              className="button"
              onClick={() => {
							  letsGo();
              }}
            >
              Comece agora a se fortalecer
            </button>
          </div>
        </div>

        <div className="box2">
          <div className="center">
            <span className="titulo negrito">
              MAIS DE
              {' '}
              <span className="corAmarela">200 MULHERES</span>
              {' '}
              JÁ FAZEM PARTE DA NOSSA MENTORIA NO TELEGRAM
            </span>
          </div>

          <div className="split">
            <span className="divSplit">
              <span className="texto textoJustificado">
                A
                {' '}
                <b>Rede Mulher com Palavra</b>
                {' '}
                é um projeto de atendimento e mentoria para mulheres de todas as idades que buscam crescer espiritual e fortalecer suas emoções, transformando assim sua vida e seus relacionamentos. Os atendimentos individuais e a mentoria são feitos diretamente comigo. Onde usarei toda minha experiência na area de aconselhamento pastoral para te ajudar a fortalecer a sua saúde emocional e espiritual.
              </span>

              <span className="descricao">
                <ul>
                  <li>Adquira um aprendizado eterno que te acompanhará por toda a vida com um custo benefício muito acessível.</li>
                  <li>Tenha um tempo especial de olhar para suas necessidades e aprenda a suprí-las.</li>
                  <li>
                    <b>Desenvolva equilíbrio na sua mente que produzirá saúde no seu corpo e alma.</b>
                  </li>
                  <li>Tenha acesso as aulas para assistir quantas vezes forem necessárias até ter ocorrido uma mudança de hábito.</li>
                </ul>
              </span>

              <span>
                Você pode assistir em qualquer dispositivo (celular, computador, tablete) e
                {' '}
                <b>tudo está disponível para você assistir a hora que desejar, 24h por dia</b>
                , no aplicativo Hotmart Sparkle.
              </span>
            </span>
            <span className="divSplit" style={{ textAlign: 'right' }}>
              <img alt="" src={deb2} width="100%" style={{ maxWidth: '300px' }} />
            </span>
          </div>
        </div>

        <div className="box4">
          <div className="center">
            <span className="titulo negrito">
              VEJA O QUE
              {' '}
              <span className="corAmarela">MINHAS MENTORIADAS</span>
              {' '}
              TEM A DIZER SOBRE O CURSO
            </span>
          </div>

          <div className="split">
            <span className="divSplit">
              <ReactPlayer url="https://vimeo.com/543905941" className="video" />
              <span className="autor">Talita Pavan</span>
            </span>
            <span className="divSplit">
              <ReactPlayer url="https://vimeo.com/543905916" className="video" />
              <span className="autor">Elis Raymann</span>
            </span>
            <span className="divSplit">
              <ReactPlayer url="https://vimeo.com/543905955" className="video" />
              <span className="autor">Rosangela Sanches</span>
            </span>
          </div>

          <div className="split">
            <span className="divSplit">
              <ReactPlayer url="https://vimeo.com/543905893" className="video" />
              <span className="autor">Maria Catarina</span>
            </span>
            <span className="divSplit">
              <ReactPlayer url="https://vimeo.com/544119501" className="video" />
              <span className="autor">Paula Angelica</span>
            </span>
            <span className="divSplit">
              <ReactPlayer url="https://vimeo.com/543906730" className="video" />
              <span className="autor">Bruna Bantin</span>
            </span>
          </div>
        </div>

        <div className="box3">
          <span className="titulo negrito">
            VEJA AS AULAS QUE VOCÊ TERÁ
            {' '}
            <span className="corAmarela">ACESSO IMEDIATO</span>
          </span>

          <div className="corpo">
            <div className="split">
              <span className="divSplit">
                <b>MÓDULO 1: VOCÊ E DEUS</b>
                {' '}
                <br />
                Aula 1: Quem se cuida se fortalece
                {' '}
                <br />
                Aula 2: Deixe Deus trabalhar
                {' '}
                <br />
                Aula 3: Alimente seu espírito
              </span>
            </div>

            <div className="split">
              <span className="divSplit">
                <b>MÓDULO 2: FORTALECENDO AS EMOÇÕES</b>
                <br />
                Aula 1: Cuide do estresse
                {' '}
                <br />
                Aula 2: Administre o tempo
                {' '}
                <br />
                Aula 3: Seja responsável
                {' '}
                <br />
                Aula 4: Palavras de encorajamento
                {' '}
                <br />
                Aula 5: Pense no que você está pensando
                {' '}
                <br />
                Aula 6: Tenha amigos
              </span>
            </div>

            <div className="split">
              <span className="divSplit">
                <b>MÓDULO 3: VOCÊ E SEU CORPO</b>
                <br />
                Aula 1: Está dominado
                {' '}
                <br />
                Aula 2: Mexa-se
                {' '}
                <br />
              </span>
            </div>
          </div>
        </div>

        <div className="box5">
          <span className="titulo negrito">
            ALÉM DE TODO CONTEÚDO VOCÊ LEVA
            {' '}
            <span className="corAmarela">+4 BÔNUS</span>
            {' '}
            EXCLUSIVOS
          </span>

          <div className="split">
            <span className="divSplit bonus">
              <img alt="" src={devocional} width="100px" />
              <div className="number">1</div>
              Devocional Mulher com Palavra
            </span>
            <span className="divSplit bonus">
              <img alt="" src={lobos} width="100px" />
              <div className="number">2</div>
              Um curso da lobos academy
            </span>
            {/* <span className="divSplit bonus">
							<img alt="" src="https://static-public.klickpages.com.br/uploads/media/file/3565656/box_-_curso_online_nivel_hard_-_compactado.png" width="100px" />
							<div className="number">3</div>
							Um curso de um amigo da mentoria
						</span>
						<span className="divSplit bonus">
							<img alt="" src={caderno} width="100px" />
							<div className="number">4</div>
							Caderno de Devocional
						</span> */}
          </div>
        </div>

        <div className="box6">
          <span className="titulo negrito">COMECE AGORA MESMO</span>

          <span className="texto">
            Adquira o curso
            {' '}
            <span className="corAmarela">Fortalecendo suas emoções</span>
            {' '}
            (
            <s>no valor de R$ 97,00</s>
            )
            <br />
            + Um livro devocional no valor de R$ 35,00
            {' '}
            <br />
            + Um curso do Thiago Lobos no valor de R$ 97,00
            {' '}
            <br />
            {/* + Um curso (surpresa) <br /> */}
            {/* + Um caderno de devocional no valor de R$ 10,00 */}
            <br />
            <b>Tudo isso por apenas R$ 47,00 (oferta por tempo limitado).</b>
          </span>
        </div>

        <div className="box7">
          <button
            className="button"
            onClick={() => {
						  letsGo();
            }}
          >
            Comece agora a se fortalecer
          </button>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
