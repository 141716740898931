import React, { useEffect, useState } from 'react';
import {
  TextField, Select, MenuItem, InputLabel,
} from '@material-ui/core';
import { isNotEmpty } from '../../services/util';
import './styles.css';

export const getField = (form: any, name: any) => form.filter((f: any) => f.name === name)[0];

function SelectCustom(props: any) {
  const { inputRef, items, ...other } = props;

  return (
    <Select
      ref={(ref: any) => {
			  inputRef(ref ? ref.inputElement : null);
      }}
      {...other}
    >
      {items.map((i: any, index: any) => (
        <MenuItem key={index} value={i.value} disabled={i.disabled}>
          {i.title}
        </MenuItem>
      ))}
    </Select>
  );
}

function ComboboxField({ field, items, onChange }: any) {
  const [value, setValue] = useState(field.value || '');

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  useEffect(() => {
    if (isNotEmpty(value)) {
      onChange({ name: field.name, value });
    }
  }, [value]);

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  if (field.isError) {
    return (
      <TextField
        label={field.label}
        value={value}
        onChange={(e) => handleChange(e)}
        variant="outlined"
        name={field.name}
        required={field.isRequired}
        type={field.type}
        className="selectComponent"
        style={{ width: '100%', display: field.isVisible === false ? 'none' : 'block' }}
        InputLabelProps={{
				  shrink: true,
        }}
        InputProps={{
				  inputComponent: SelectCustom,
				  inputProps: { items },
        }}
        error
        fullWidth
        helperText={field.errorMessage ? field.errorMessage : 'Campo invalido'}
      />
    );
  }

  return (
    <TextField
      label={field.label}
      value={value}
      onChange={(e) => handleChange(e)}
      variant="outlined"
      name={field.name}
      required={field.isRequired}
      type={field.type}
      className="selectComponent"
      style={{ width: '100%', display: field.isVisible === false ? 'none' : 'block' }}
      InputLabelProps={{
			  shrink: true,
      }}
      InputProps={{
			  inputComponent: SelectCustom,
			  inputProps: { items },
      }}
    />
  );
}

export default ComboboxField;
