import React, { useEffect } from 'react';
import './styles.css';

import { Button } from '@material-ui/core';
import FileSaver from 'file-saver';
import { MdFileDownload, MdShoppingCart } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ShareSocial from '../../components/ShareSocial';
import { useCarrinho } from '../../contexts/carrinho';
import { useLoja } from '../../contexts/loja';
import { extArquivo } from '../../services/util';

import api from '../../services/api';
import MoneyFormat from '../../services/money';

const DetalhesLoja: React.FC = () => {
  const { dataByID, loading, loadDataByID } = useLoja();
  const { saveCarrinho } = useCarrinho();
  const history = useHistory();

  const params = useParams<{ product_id: string }>();

  useEffect(() => {
    loadDataByID(params.product_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.product_id]);

  const salvarNoCarrinho = () => {
    saveCarrinho(dataByID);
    history.push('/carrinho');
  };

  const download = async (arquivo: string, title: string) => {
    // arquivo = "http://localhost:3333/static/pdf.pdf";
    // arquivo = "http://localhost:3333/static/png.png";
    // arquivo = "http://localhost:3333/static/jpg.jpg";
    // arquivo = "http://localhost:3333/static/jpeg.jpeg";
    // arquivo = "http://localhost:3333/static/docx.docx";
    // arquivo = "http://localhost:3333/static/zip.zip";
    // arquivo = "http://localhost:3333/static/txt.txt";

    if (window.location.host.indexOf('www.') === -1) {
      arquivo = arquivo.replace('/www.', '/');
    }

    const { extensao, tipo } = extArquivo(arquivo);

    try {
      await api
        .get(arquivo, {
          responseType: 'blob',
        })
        .then((res: any) => {
          const file = new File([res.data], `${title}${extensao}`, {
            type: tipo,
          });

          FileSaver.saveAs(file);

          toast('Arquivo baixado com sucesso', {
            position: toast.POSITION.BOTTOM_LEFT,
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          });
        });
    } catch { }
  };

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  return (
    <div id="contentGalleryLoja">
      <div className="sectionLoja">
        <img src={dataByID.url} alt={dataByID.title} title={dataByID.title} className="imagemGrande" />

        <div className="title">{dataByID.title}</div>

        {!dataByID.value || dataByID.value === 0 ? <div className="valorLoja">Gratuito</div> : <span className="valorLoja">{MoneyFormat(dataByID.value)}</span>}

        <div className="description" dangerouslySetInnerHTML={{ __html: dataByID.description }} />

        {dataByID.download && (
        <>
          <br />
          <Button variant="contained" onClick={() => download(dataByID.url_download, dataByID.title)}>
            <MdFileDownload />
            Clique aqui para baixar o arquivo em anexo
          </Button>
          <br />
        </>
        )}

        {dataByID.disable_shopping_cart && (
        <>
          <br />
          <Button variant="contained" color="secondary" onClick={() => salvarNoCarrinho()}>
            <MdShoppingCart />
            Adicionar ao carrinho
          </Button>
          <br />
        </>
        )}
      </div>

      <ShareSocial shareUrl={window.location.href} title={dataByID.title} />
    </div>
  );
};

export default DetalhesLoja;
