import React, { useEffect } from 'react';
import { useEventos } from '../../contexts/eventos';

import './styles.css';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import { formatarDataHora, formatarURL } from '../../services/util';
import MoneyFormat from '../../services/money';

const Eventos: React.FC = () => {
  const { data, loadData, loading } = useEventos();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  if (data.length === 0) {
    return (
      <div className="nenhumRegistro">
        <p>Desculpe, no momento não temos eventos disponível.</p>
        <p>Confira novamente em breve. :)</p>
        <br />
        <p>Equipe Mulher com Palavra</p>
      </div>
    );
  }

  document.title = 'Eventos | Mulher com Palavra';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Confira nossos eventos e a agenda do Mulher com Palavra pelo mundo.';

  return (
    <div id="contentEvent">
      {data.length > 0 && (
      <div className="eventoItemGrande">
        <Link to={`/galeria/eventos/${data[0]._id}/${formatarURL(data[0].title)}`} title={data[0].title}>
          <img src={data[0].url} alt={data[0].title} title={data[0].title} className="imagemGrande" />

          <span className="eventoDetalhes" style={{ width: 500 }}>
            {data[0].start_date && !data[0].end_date && <div className="dataEvento">{formatarDataHora(data[0].start_date)}</div>}

            {data[0].start_date && data[0].end_date && (
            <div className="dataEvento">
              {formatarDataHora(data[0].start_date)}
              {' '}
              {'>'}
              {' '}
              {formatarDataHora(data[0].end_date)}
            </div>
            )}

            <div
              style={{
									  display: 'flex',
									  flexDirection: 'row',
									  color: '#333',
									  alignItems: 'center',
									  justifyContent: 'space-between',
              }}
            >
              <div className="tipoEvento">
                Evento
                {data[0].type}
              </div>

              {!data[0].value || data[0].value === 0 ? <div className="valorEvento">Gratuito</div> : <span className="valorEvento">{MoneyFormat(data[0].value)}</span>}
            </div>
          </span>

          <div className="title">{data[0].title.length > 60 ? `${data[0].title.substr(0, 55)}...` : data[0].title}</div>
        </Link>

        {data[0].link && (
        <Link to={{ pathname: data[0].link }} target="_blank" title="Clique aqui para se inscrever" className="btnVejaMais" style={{ marginTop: 10, color: '#fff', fontWeight: 'normal' }}>
          Inscrever
        </Link>
        )}
      </div>
      )}

      <div className="eventoItem">
        {data.forEach((item: any, i: number) => {
					  if (i > 0) {
					    return (
  <div
    key={item._id}
    style={{
									  textAlign: 'center',
									  display: 'flex',
									  flexDirection: 'column',
    }}
  >
    <Card title={item.title} url={`/galeria/eventos/${item._id}/${formatarURL(item.title)}`} image={item.url}>
      <span className="eventoDetalhes">
        {item.start_date && !item.end_date && <div className="dataEvento">{formatarDataHora(item.start_date)}</div>}

        {item.start_date && item.end_date && (
        <div className="dataEvento">
          {formatarDataHora(item.start_date)}
          {' '}
          {'>'}
          {' '}
          {formatarDataHora(item.end_date)}
        </div>
        )}

        <div
          style={{
												  display: 'flex',
												  flexDirection: 'row',
												  color: '#333',
												  alignItems: 'center',
												  justifyContent: 'center',
          }}
        >
          <div className="tipoEvento">
            Evento
            {item.type}
          </div>

          |

          {!item.value || item.value === 0 ? <div className="valorEvento">Gratuito</div> : <span className="valorEvento">{MoneyFormat(item.value)}</span>}
        </div>
      </span>
    </Card>

    {item.link && (
    <Link to={{ pathname: item.link }} target="_blank" title="Clique aqui para se inscrever" className="btnVejaMais" style={{ margin: '0 10px' }}>
      Inscrever
    </Link>
    )}
  </div>
					    );
					  }
        })}
      </div>
    </div>
  );
};

export default Eventos;
