import React, {
  createContext, useContext, useState, ReactNode,
} from 'react';
import api from '../services/api';

interface IContextProps {
	data: any;
	loadData: () => void;
	loading: boolean;
}

const TestemunhosContext = createContext({} as IContextProps);

interface IProps {
	children: ReactNode;
}

export function TestemunhosProvider({ children }: IProps) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);

    const res = await api.get('/web/testimony?qtd=999');

    setData(res.data.items);
    setLoading(false);
  };

  return (
    <TestemunhosContext.Provider value={{ data, loadData, loading }}>
      {children}
    </TestemunhosContext.Provider>
  );
}

export function useTestemunhos() {
  return useContext(TestemunhosContext);
}
