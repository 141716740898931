import React, { useEffect, useState, FormEvent } from 'react';
import './styles.css';

import Carousel from 'react-material-ui-carousel';
import { Link } from 'react-router-dom';
import { MdPlayCircleFilled } from 'react-icons/md';
import swal from 'sweetalert';
import { Button } from '@material-ui/core';
import debora from '../../assets/debora.jpeg';
import oracao from '../../assets/oracao.jpg';
import { usePrincipal } from '../../contexts/principal';
import fundo2 from '../../assets/fundo2.png';
import Card from '../../components/Card';
import CarouselCard from '../../components/CarouselCard';

import { formatarDataHora, formatarURL, isEmpty } from '../../services/util';
import MoneyFormat from '../../services/money';
import InputField, { getField } from '../../components/InputField';

const Principal: React.FC = () => {
  const {
    dataEventos, dataBanners, dataByFilter, loadDataByFilter, saveOracao, dataTestemunhos, loadDataBanners, loadDataEventos, loadDataTestemunhos, saveNewsletter, loading,
  } = usePrincipal();

  const [formOracao, setFormOracao] = useState([
    {
      name: 'name', label: 'Nome', value: '', isRequired: true, isError: false,
    },
    {
      name: 'phone', label: 'Telefone', value: '', isRequired: true, isError: false, mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    },
    {
      name: 'email', label: 'E-mail', value: '', isRequired: true, isError: false,
    },
    {
      name: 'message', label: 'Mensagem', value: '', isRequired: true, isError: false,
    },
  ]);

  const [formNewsletter, setFormNewsletter] = useState([
    {
      name: 'name', label: 'Nome', value: '', isRequired: true, isError: false,
    },
    {
      name: 'email', label: 'E-mail', value: '', isRequired: true, isError: false,
    },
  ]);

  const [dataNewsletter, setDataNewsletter] = useState<any>(null);
  const [isNewsletterValid, setIsNewsletterValid] = useState(false);

  const [dataOracao, setDataOracao] = useState<any>(null);
  const [isOracaoValid, setIsOracaoValid] = useState(false);

  useEffect(() => {
    const resValid = formNewsletter.every((f: any) => f.isError === false);
    setIsNewsletterValid(resValid);
  }, [formNewsletter]);

  useEffect(() => {
    async function submit() {
      if (isNewsletterValid && dataNewsletter) {
        await saveNewsletter(dataNewsletter);

        setFormNewsletter([
          ...formNewsletter.map((f: any) => {
            f.value = '';

            return f;
          }),
        ]);
      }
    }

    submit();
  }, [isNewsletterValid, dataNewsletter]);

  useEffect(() => {
    const resValid = formOracao.every((f: any) => f.isError === false);
    setIsOracaoValid(resValid);
  }, [formOracao]);

  useEffect(() => {
    async function submit() {
      if (isOracaoValid && dataOracao) {
        await saveOracao(dataOracao);

        setFormOracao([
          ...formOracao.map((f: any) => {
            f.value = '';

            return f;
          }),
        ]);
      }
    }

    submit();
  }, [isOracaoValid, dataOracao]);

  useEffect(() => {
    loadDataBanners();
    loadDataByFilter();
    loadDataEventos();
    loadDataTestemunhos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSalvarOracao = async (e: FormEvent) => {
    e.preventDefault();

    const data = {
      name: getField(formOracao, 'name').value,
      email: getField(formOracao, 'email').value,
      phone: getField(formOracao, 'phone').value,
      message: getField(formOracao, 'message').value,
    };

    setDataOracao(data);

    Object.entries(data).map((d: any) => {
      const [k, v] = d;

      const item = formOracao.find((f: any) => f.name === k);

      if (item) {
        item.isError = false;

        if (item.isRequired) {
          if (isEmpty(v)) {
            item.isError = true;
          }
        }
      }

      setFormOracao([...formOracao]);
    });
  };

  const handleSalvarNewsletter = async (e: any) => {
    e.preventDefault();

    const data = {
      name: getField(formNewsletter, 'name').value,
      email: getField(formNewsletter, 'email').value,
    };

    setDataNewsletter(data);

    Object.entries(data).map((d: any) => {
      const [k, v] = d;

      const item = formNewsletter.find((f: any) => f.name === k);

      if (item) {
        item.isError = false;

        if (item.isRequired) {
          if (isEmpty(v)) {
            item.isError = true;
          }
        }
      }

      setFormNewsletter([...formNewsletter]);
    });
  };

  const onChangeNewsletter = async ({ name, value }: any) => {
    setFormNewsletter([
      ...formNewsletter.map((f: any) => {
        if (f.name === name) {
          f.value = value;
        }

        return f;
      }),
    ]);
  };

  const onChangeOracao = async ({ name, value }: any) => {
    setFormOracao([
      ...formOracao.map((f: any) => {
        if (f.name === name) {
          f.value = value;
        }

        return f;
      }),
    ]);
  };

  if (loading) {
    return <div>Aguarde, carregando...</div>;
  }

  document.title = 'Mulher com Palavra | Debora Santana';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Ajudando mulheres que querem viver o que Deus tem para elas através da Palavra.';

  return (
    <div id="contentPrincipal">
      <div className="boxBanner">
        {dataBanners.length > 0 && (
        <Carousel navButtonsAlwaysVisible={dataBanners.length > 1} indicators>
          {dataBanners.map((item: any) => (
            <Link to={{ pathname: item.link }} target="_blank" key={item._id} title={item.title}>
              <img src={item.url} alt={item.title} title={item.title} />
            </Link>
          ))}
        </Carousel>
        )}
      </div>

      <div className="boxNewsletter">
        <div className="newsletter">
          <div>INSCREVA-SE E RECEBA OS NOSSOS CONTEÚDOS EM PRIMEIRA MÃO</div>

          <form onSubmit={handleSalvarNewsletter} noValidate autoComplete="off">
            <div className="linha">
              <InputField field={getField(formNewsletter, 'name')} onChange={onChangeNewsletter} />

              <InputField field={getField(formNewsletter, 'email')} onChange={onChangeNewsletter} />

              <Button variant="contained" type="submit">
                Inscrever
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className="boxSobre">
        <div className="sobre">
          <div>
            <h3>O MULHER COM PALAVRA</h3>
            <p>
              Olá, eu sou a
              {' '}
              <b>Debora Santana</b>
              , fundadora do projeto
              <b>Mulher com Palavra</b>
              .
            </p>
            <p>
              O
              {' '}
              <b>Mulher com Palavra</b>
              {' '}
              é um projeto de Deus para minha vida, um presente que envolve muitas mulheres.
            </p>
            <p>Não é um projeto somente para a minha igreja local, e sim, para a igreja que somos nós, mulheres que Deus chamou para sermos carta escrita.</p>
            <p>Por isso é interdenominacional, envolvendo mulheres de várias idades, igrejas e denominações em vários locais do mundo.</p>
            <p>Mulheres que querem viver o que Deus tem para elas através da Palavra.</p>
          </div>

          <div style={{ alignItems: 'center' }}>
            <img src={debora} alt="Debora Santana" title="Debora Santana" className="imagemSobre" />
          </div>

          <div style={{ width: 201 }}>
            <Link to="/sobre" title="Clique aqui e veja mais sobre isso" className="btnVejaMais" style={{ margin: 0 }}>
              Conheça nossa história
            </Link>
          </div>
        </div>
      </div>

      {(dataByFilter.video.length > 0 || dataByFilter.notvideo.length > 0) && (
      <div
        className="boxMinistracoes"
        style={{
						  backgroundImage: `url(${fundo2})`,
						  backgroundSize: 'cover',
        }}
      >
        <h3>PALAVRAS</h3>

        <div className="boxCarousel">
          <CarouselCard data={dataByFilter.all} url="/palavra/" />
        </div>

        <div className="boxNotCarousel">
          {dataByFilter.video.length > 0 && (
          <div className="eventoItemGrande">
            <Link to={`/palavra/${dataByFilter.video[0]._id}/${formatarURL(dataByFilter.video[0].title)}`} title={dataByFilter.video[0].title}>
              <div
                style={{
											  backgroundImage: `url(${dataByFilter.video[0].url})`,
											  backgroundSize: 'cover',
											  minWidth: 500,
											  minHeight: 300,
                }}
                title={dataByFilter.video[0].title}
              />

              <div className="iconPlay">
                <MdPlayCircleFilled />
              </div>

              <div className="title">{dataByFilter.video[0].title.length > 60 ? `${dataByFilter.video[0].title.substr(0, 55)}...` : dataByFilter.video[0].title}</div>
            </Link>
          </div>
          )}

          <div>
            <div className="eventoItem">
              {dataByFilter.video.forEach((item: any, i: number) => {
									  if (i > 0) {
									    return <Card key={item._id} title={item.title} url={`/palavra/${item._id}/${formatarURL(item.title)}`} image={item.url} video />;
									  }
              })}
            </div>
          </div>

          <div style={{ marginTop: 50 }}>
            <div className="eventoItem">
              {dataByFilter.notvideo.map((item: any) => (
                <Card key={item._id} title={item.title} url={`/palavra/${item._id}/${formatarURL(item.title)}`} image={item.url} />
              ))}
            </div>
          </div>
        </div>

        <Link to="/palavras" title="Clique aqui e veja mais sobre isso" className="btnVejaMais">
          Ver mais
        </Link>
      </div>
      )}

      {dataEventos.length > 0 && (
      <div className="boxEventos">
        <div className="eventos">
          <h3>PRÓXIMOS EVENTOS</h3>

          <div className="boxCarousel">
            <CarouselCard data={dataEventos} url="/galeria/eventos/">
              {/* data do evento: {item.title} */}
            </CarouselCard>
          </div>

          <div className="boxNotCarouselEventos">
            <div className="eventoItem">
              {dataEventos.map((item: any) => (
                <div
                  key={item._id}
                  style={{
											  textAlign: 'center',
											  display: 'flex',
											  flexDirection: 'column',
                  }}
                >
                  <Card title={item.title} url={`/galeria/eventos/${item._id}/${formatarURL(item.title)}`} image={item.url}>
                    <span className="eventoDetalhes">
                      {item.start_date && !item.end_date && <div className="dataEvento">{formatarDataHora(item.start_date)}</div>}

                      {item.start_date && item.end_date && (
                      <div className="dataEvento">
                        {formatarDataHora(item.start_date)}
                        {' '}
                        {'>'}
                        {' '}
                        {formatarDataHora(item.end_date)}
                      </div>
                      )}

                      <div
                        style={{
														  display: 'flex',
														  flexDirection: 'row',
														  color: '#333',
														  alignItems: 'center',
														  justifyContent: 'center',
                        }}
                      >
                        <div className="tipoEvento">
                          Evento
                          {item.type}
                        </div>

                        |

                        {!item.value || item.value === 0 ? <div className="valorEvento">Gratuito</div> : <span className="valorEvento">{MoneyFormat(item.value)}</span>}
                      </div>
                    </span>
                  </Card>

                  {/* {item.link && (
												<Link
													to={{ pathname: item.link }}
													target="_blank"
													title="Clique aqui para se inscrever"
													className="btnVejaMais"
													style={{ margin: "0 10px" }}
												>
													Inscrever
												</Link>
											)} */}
                </div>
              ))}
            </div>
          </div>

          <Link to="/eventos" title="Clique aqui e veja mais sobre isso" className="btnVejaMais">
            Ver próximos
          </Link>
        </div>
      </div>
      )}

      {dataTestemunhos.length > 0 && (
      <div className="boxTestemunhos" style={{ background: '#f8e4e3' }}>
        <div className="testemunhos">
          <h3>TESTEMUNHOS</h3>

          <div className="boxCarousel">
            <CarouselCard data={dataTestemunhos} url="/testemunhos" withLink={false} withContent limitContent={500} />
          </div>

          <div className="boxNotCarouselTestemunhos">
            <div className="testemunhoItem">
              {dataTestemunhos.map((item: any) => {
									  const largura = dataTestemunhos.length === 1 ? '75%' : dataTestemunhos.length === 2 ? '50%' : '33%';

									  return (
  <div className="linha" key={item._id} style={{ width: largura }}>
    <img src={item.url} alt={item.name} title={item.name} />
    <div className="title">{item.name}</div>
    <div
      className="description"
      dangerouslySetInnerHTML={{
													  __html: item.message,
      }}
    />
  </div>
									  );
              })}
            </div>
          </div>

          <Link to="/testemunhos" title="Clique aqui e veja mais sobre isso" className="btnVejaMais">
            Ver todos
          </Link>
        </div>
      </div>
      )}

      <div
        className="boxOracao"
      >
        <div className="oracao">
          <div style={{ alignItems: 'center' }}>
            <img src={oracao} alt="Precisa de Oração" title="Precisa de Oração" className="imagemOracao" />
          </div>

          <div>
            <form onSubmit={handleSalvarOracao} noValidate autoComplete="off">
              <h3>Precisa de Oração?</h3>

              <span
                style={{
									  fontSize: 12,
									  color: '#555',
									  marginTop: 5,
                }}
              >
                Envie seu pedido, e nossa equipe de intercessão estará orando por ele.
              </span>

              <InputField field={getField(formOracao, 'name')} onChange={onChangeOracao} />

              <InputField field={getField(formOracao, 'phone')} onChange={onChangeOracao} regex />

              <InputField field={getField(formOracao, 'email')} onChange={onChangeOracao} />

              <InputField field={getField(formOracao, 'message')} onChange={onChangeOracao} multiline />

              <Button variant="contained" color="secondary" type="submit">
                Enviar
              </Button>

              <br />

              <span
                style={{
									  fontSize: 12,
									  color: '#555',
									  marginTop: 5,
									  fontStyle: 'italic',
                }}
              >
                "E tudo o que pedirem em oração, se crerem, vocês receberão".
                <br />
                Mateus 21:22
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Principal;
