import React, {
  createContext, useContext, useState, ReactNode,
} from 'react';
import api from '../services/api';

interface IContextProps {
	data: any;
	dataByID: any;
	loadData: () => void;
	cancelar: (value: string) => void;
	loadDataByID: (value: string) => void;
	loading: boolean;
}

const PedidoContext = createContext({} as IContextProps);

interface IProps {
	children: ReactNode;
}

export function PedidoProvider({ children }: IProps) {
  const [data, setData] = useState([]);
  const [dataByID, setDataByID] = useState({});
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);

    const res = await api.get('/web/customer/order');

    setData(res.data.items);
    setLoading(false);
  };

  const loadDataByID = async (order_id: string) => {
    setLoading(true);

    const res = await api.get(`/web/customer/order/${order_id}`);

    setDataByID(res.data);
    setLoading(false);
  };

  const cancelar = async (order_id: string) => {
    setLoading(true);

    const res = await api.get(`/web/customer/order/cancel/${order_id}`);

    const newData = data.map((i: any) => {
      if (i._id === order_id) {
        i = res.data;
      }

      return i;
    });

    setData(newData as never[]);
    setLoading(false);
  };

  return (
    <PedidoContext.Provider
      value={{
        data, dataByID, loadData, loadDataByID, cancelar, loading,
      }}
    >
      {children}
    </PedidoContext.Provider>
  );
}

export function usePedido() {
  return useContext(PedidoContext);
}
