import React, {
  createContext, useContext, useState, ReactNode,
} from 'react';
import swal from 'sweetalert';
import api from '../services/api';

interface IContextProps {
	saveContato: (value: object) => void;
	loading: boolean;
}

const ContatoContext = createContext({} as IContextProps);

interface IProps {
	children: ReactNode;
}

export function ContatoProvider({ children }: IProps) {
  const [loading, setLoading] = useState(false);

  const saveContato = async (data: object) => {
    setLoading(true);

    await api.post('/web/contact', data);
    swal('Contato enviado com sucesso', '', 'success');

    setLoading(false);
  };

  return <ContatoContext.Provider value={{ saveContato, loading }}>{children}</ContatoContext.Provider>;
}

export function useContato() {
  return useContext(ContatoContext);
}
