import React, { useState, FormEvent, useEffect } from 'react';
import './styles.css';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Customer, useCadastro } from '../../contexts/cadastro';
import InputField, { getField } from '../../components/InputField';
import ComboboxField from '../../components/ComboboxField';
import { getCEP, getCidades, getEstados } from '../../util';
import { isEmpty, isNotEmpty } from '../../services/util';

const Cadastro: React.FC = () => {
  const [form, setForm] = useState([
    { name: '_id', label: '', value: '' },
    {
      name: 'name', label: 'Nome', value: '', isRequired: true, isError: false,
    },
    {
      name: 'email', label: 'E-mail', value: '', isRequired: true, isError: false,
    },
    {
      name: 'password', label: 'Senha', value: '', isRequired: true, isError: false,
    },
    {
      name: 'cpf', label: 'CPF', value: '', isRequired: true, mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    },
    {
      name: 'phone', label: 'Telefone', value: '', isRequired: true, isError: false, mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    },
    { name: 'dtNasc', label: 'Data de nascimento', value: '' },
    {
      name: 'address_postal_code', label: 'CEP', value: '', isRequired: true, isError: false, mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    },
    {
      name: 'address_street', label: 'Logradouro', value: '', isRequired: true, isError: false,
    },
    {
      name: 'address_number', label: 'Numero', value: '', isRequired: true, isError: false,
    },
    { name: 'address_complement', label: 'Complemento', value: '' },
    {
      name: 'address_district', label: 'Bairro', value: '', isRequired: true, isError: false,
    },
    {
      name: 'address_city', label: 'Cidade', value: '', isRequired: true, isError: false,
    },
    {
      name: 'address_state', label: 'Estado', value: '', isRequired: true, isError: false,
    },
    { name: 'address_email', label: '', value: '' },
  ]);

  const { customer, loginCadastro } = useCadastro();
  const { saveCadastro } = useCadastro();

  const history = useHistory();
  const match = useRouteMatch();

  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);

  const [data, setData] = useState<Customer | null>(null);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    async function init() {
      const items = await getEstados();
      setEstados(items);
    }

    init();
  }, []);

  useEffect(() => {
    if (customer) {
      let newForm: any = [];

      Object.entries(customer).map((d: any) => {
        const [k, v] = d;

        newForm = form.map((f: any) => {
          if (f.name === k) {
            f.value = v;
          }

          return f;
        });
      });

      setForm([...newForm]);
    }
  }, [customer]);

  useEffect(() => {
    const resValid = form.every((f: any) => f.isError === false);
    setIsValid(resValid);
  }, [form]);

  useEffect(() => {
    async function submit() {
      if (isValid && data) {
        await saveCadastro(data);

        if (!customer) {
          await loginCadastro({
            email: data.name,
            password: data.password,
          });
        }

        if (match.path.indexOf('/carrinho') > -1) {
          history.push('/carrinho/endereco');
        } else {
          history.push('/perfil');
        }
      }
    }

    submit();
  }, [isValid]);

  const handleSalvar = async (e: FormEvent) => {
    e.preventDefault();

    const data: Customer = {
      _id: getField(form, '_id').value,
      name: getField(form, 'name').value,
      email: getField(form, 'email').value,
      password: getField(form, 'password').value,
      cpf: getField(form, 'cpf').value,
      phone: getField(form, 'phone').value,
      dtNasc: getField(form, 'dtNasc').value,
      address_postal_code: getField(form, 'address_postal_code').value,
      address_street: getField(form, 'address_street').value,
      address_number: getField(form, 'address_number').value,
      address_complement: getField(form, 'address_complement').value,
      address_district: getField(form, 'address_district').value,
      address_city: getField(form, 'address_city').value,
      address_state: getField(form, 'address_state').value,
      address_email: getField(form, 'address_email').value,
    };

    setData(data);

    Object.entries(data).map((d: any) => {
      const [k, v] = d;

      const item = form.find((f: any) => f.name === k);

      if (item) {
        item.isError = false;

        if (item.isRequired) {
          if (isEmpty(v)) {
            item.isError = true;
          }
        }
      }

      setForm([...form]);
    });
  };

  const onChange = async ({ name, value }: any) => {
    setForm([
      ...form.map((f: any) => {
        if (f.name === name) {
          f.value = value;
        }

        return f;
      }),
    ]);

    if (name === 'address_postal_code' && isNotEmpty(value)) {
      const vlr = value.replace(/[-._]/gu, '');

      if (vlr.length === 8) {
        const items = await getCEP(vlr);

        if (!items.erro) {
          setForm([
            ...form.map((f: any) => {
              if (f.name === 'address_street') {
                f.value = items.logradouro;
              }
              if (f.name === 'address_complement') {
                f.value = items.complemento;
              }
              if (f.name === 'address_district') {
                f.value = items.bairro;
              }
              if (f.name === 'address_state') {
                f.value = items.uf;
              }
              if (f.name === 'address_city') {
                f.value = items.ibge;
              }

              return f;
            }),
          ]);
        }
      }
    }
  };

  const onChangeCombobox = async (e: any) => {
    onChange({ name: e.name, value: e.value });

    if (e.name === 'address_state' && isNotEmpty(e.value)) {
      const items = await getCidades(e.value);
      setCidades(items);
    }
  };

  document.title = 'Cadastro | Mulher com Palavra';
  const meta = document.getElementsByTagName('META')[2] as HTMLMetaElement;
  meta.content = 'Tem alguma dúvida ou recado? Entre em contato conosco.';

  return (
    <div id="contentCadastro">
      <form onSubmit={handleSalvar} noValidate autoComplete="off">
        <h3>INFORMAÇÕES DA CONTA</h3>

        <div className="linha">
          <div className="coluna">
            <InputField field={getField(form, 'name')} onChange={onChange} />
          </div>

          <div className="coluna">
            <InputField field={getField(form, 'dtNasc')} type="date" onChange={onChange} />
          </div>
        </div>

        <div className="linha">
          <div className="coluna">
            <InputField field={getField(form, 'email')} onChange={onChange} />
          </div>

          <div className="coluna">
            <InputField field={getField(form, 'password')} type="password" onChange={onChange} />
          </div>
        </div>

        <div className="linha">
          <div className="coluna">
            <InputField field={getField(form, 'phone')} onChange={onChange} regex />
          </div>

          <div className="coluna">
            <InputField field={getField(form, 'cpf')} onChange={onChange} regex />
          </div>
        </div>

        <div className="linha">
          <div className="coluna">
            <InputField field={getField(form, 'address_postal_code')} onChange={onChange} regex />
          </div>
          <div className="coluna" />
        </div>

        <div className="linha">
          <div className="coluna">
            <InputField field={getField(form, 'address_street')} onChange={onChange} />
          </div>

          <div className="coluna">
            <InputField field={getField(form, 'address_number')} onChange={onChange} />
          </div>
        </div>

        <div className="linha">
          <div className="coluna">
            <InputField field={getField(form, 'address_complement')} onChange={onChange} />
          </div>

          <div className="coluna">
            <InputField field={getField(form, 'address_district')} onChange={onChange} />
          </div>
        </div>

        <div className="linha">
          <div className="coluna">
            <ComboboxField field={getField(form, 'address_state')} onChange={onChangeCombobox} items={estados} />
          </div>

          <div className="coluna">
            <ComboboxField field={getField(form, 'address_city')} onChange={onChangeCombobox} items={cidades} />
          </div>
        </div>

        <div className="linha">
          <div className="coluna" />
          <div className="coluna">
            <Button variant="contained" color="secondary" type="submit">
              Salvar
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Cadastro;
