import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { useHeader } from './contexts/header';

import Header from './components/Header';

import Principal from './pages/Principal';
import Contato from './pages/Contato';
import Footer from './components/Footer';
import Sobre from './pages/Sobre';
import Equipe from './pages/Equipe';
import Loja from './pages/Loja';
import Palavras from './pages/Palavras';
import Eventos from './pages/Eventos';
import GaleriaEventos from './pages/GaleriaEventos';
import DetalhesLoja from './pages/DetalhesLoja';
import Palavra from './pages/Palavra';
import Testemunhos from './pages/Testemunhos';
import Cadastro from './pages/Cadastro';
import Perfil from './pages/Perfil';
import Login from './pages/Login';
import LandingPage from './pages/LandingPage';
import { useCadastro } from './contexts/cadastro';
import Carrinho from './pages/Carrinho';

const BRoutes: React.FC = ({ children }: any) => <BrowserRouter>{children}</BrowserRouter>;

const Routes: React.FC = () => {
  const { setOpen } = useHeader();
  const { customer } = useCadastro();

  const pageName = '/fortalecendo-sua-saude-emocional';

  if (window.location.pathname.indexOf(pageName) > -1) {
    return (
      <BRoutes>
        <Route path={pageName} component={LandingPage} />
      </BRoutes>
    );
  }

  return (
    <BRoutes>
      <Header />

      <div className="contentRoute" onClick={() => setOpen(false)}>
        <Route path="/" component={Principal} exact />
        <Route path="/contato" component={Contato} />
        <Route path="/sobre" component={Sobre} />
        <Route path="/palavras" component={Palavras} />
        <Route path="/equipe" component={Equipe} />
        <Route path="/loja" component={Loja} />
        <Route path="/eventos" component={Eventos} />
        <Route path="/testemunhos" component={Testemunhos} />
        <Route path="/carrinho" component={Carrinho} />

        {customer && <Route path="/perfil" component={Perfil} />}

        {!customer && (
        <>
          <Route path="/cadastro" component={Cadastro} />
          <Route path="/login" component={Login} />
        </>
        )}

        <Route path="/palavra/:message_id/:message_text" component={Palavra} />
        <Route path="/galeria/eventos/:event_id/:event_title" component={GaleriaEventos} />
        <Route path="/detalhes/loja/:product_id/:product_title" component={DetalhesLoja} />
      </div>

      <Footer />
    </BRoutes>
  );
};

export default Routes;
