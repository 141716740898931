import { Button } from '@material-ui/core';
import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useCadastro } from '../../contexts/cadastro';
import { useCarrinho } from '../../contexts/carrinho';
import Cadastro from '../Cadastro';
import Etapa1 from './etapa1';
import Etapa2 from './etapa2';
import Etapa3 from './etapa3';
import './styles.css';

const Carrinho: React.FC = () => {
  const { qtd } = useCarrinho();
  const { customer } = useCadastro();
  const history = useHistory();

  const firstPage = () => {
    history.push('/carrinho');
  };

  const secondPage = () => {
    history.push('/carrinho/endereco');
  };

  const thirdPage = () => {
    history.push('/carrinho/pagamento');
  };

  return (
    <div className="carrinhoCompras">
      <div>
        <Button variant="contained" onClick={() => firstPage()} className="carrinhoComprasBtn">
          Etapa 1
        </Button>

        <Button variant="contained" disabled={qtd === 0} onClick={() => secondPage()}>
          Etapa 2
        </Button>

        <Button variant="contained" disabled={qtd === 0 || !customer} onClick={() => thirdPage()}>
          Etapa 3
        </Button>
      </div>

      <Switch>
        <Route path="/carrinho" component={Etapa1} exact />
        <Route path="/carrinho/endereco" component={Etapa2} />
        <Route path="/carrinho/cadastro" component={Cadastro} />
        <Route path="/carrinho/pagamento" component={Etapa3} />
      </Switch>
    </div>
  );
};

export default Carrinho;
